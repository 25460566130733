import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyCQ5cDsJWZ4FtxfneYij_Owbd5MKgTQKR4",
  authDomain: "nordantia-315207.firebaseapp.com",
  projectId: "nordantia-315207",
  storageBucket: "nordantia-315207.appspot.com",
  messagingSenderId: "900724848978",
  appId: "1:900724848978:web:6161a763d23f0d25a2a28b",
  measurementId: "G-K7DQSCVSP9",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Messaging
const messaging = getMessaging(app);

export { messaging };
