<template>
  <div class="weeks">
    <button @click="addNewWeek()" class="lunch-fill">{{ "+ Lisää viikko" }}</button>
    <router-link
      class="week"
      v-for="week in lunch.weeks"
      :key="week"
      :to="'/lunch-day/' + week.id"
    >
      <span>{{ "Viikko " + getWeekNumber(week.start_week) }}</span>
      <div class="lunch-date">
        {{ formatDate(week.start_week) + " - " + formatDate(week.end_week) }}
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "LunchWeeks",
  props: [],
  mounted() {},
  data: () => ({}),
  methods: {
    addNewWeek() {
      let data = {};
      this.$http
        .post(
          this.$hostname + "/lunch-weeks",
          data,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          this.fetchLunchWeeks();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
  },
};
</script>

<style scoped>
.lunch-fill {
  background: #408CFF;
  border: none;
  border-radius: 10px;
  margin-bottom: 8px;
  padding: 8px;
  width: 150px;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
}
.weeks {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.week {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  background-color: #fafafa;
  border: 1px solid #dedede;
  border-radius: 10px;
  color: #000;
}
.week span {
  background-color: #408CFF;
  text-align: center;
  width: 100px;
  padding: 8px;
  border-radius: 10px;
  color: #fff;
}
.week .lunch-date {
  padding: 8px;
}
</style>
