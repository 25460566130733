<template>
  <div id="extra_drink_modal" class="modal">
    <div class="question">
      <div class="input">
        <label class="deactive" for="title">{{
          $t("information.title")
        }}</label>
        <input
          class="active"
          type="text"
          id="title"
          v-model="item.title"
          placeholder="i.g. cheese (L/V/G)"
        />
      </div>
      <div class="input">
        <label class="deactive" for="price">{{ $t("history.price") }}</label>
        <input class="active" type="text" id="price" v-model="item.price" />
        <span>€</span>
      </div>

      <!-- tax -->
      <div class="row tax-row">
        <div class="tax">
          <input v-model="item.tax" type="checkbox" name="tax" id="tax" />
          <label for="tax">
            {{ "This item includes alcoholic beverages (24% VAT)" }}
          </label>
        </div>

        <div class="tax-note">
          {{ "*The default VAT rate is 14% for all items." }}
        </div>
      </div>

      <button v-if="edit" @click="saveExtraDrink" class="fill">
        {{ $t("info.save_changes") }}
      </button>
      <button v-else @click="addNewExtraDrink" class="fill">
        {{ $t("information.save") }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    addNewExtraDrink() {
      if (this.item.title) {
        this.global_loading.show = true;
        let data = {
          title: this.item.title,
          price: this.replaceComma(this.item.price),
          tax: this.item.tax ? 24 : 14,
        };

        this.$http
          .post(
            this.$hostname + "/categories/" + this.category_id + "/items",
            data,
            this.restaurant_token.config
          )
          .then((resp) => {
            this.successRequest();
            this.done();
          })
          .catch((err) => {
            this.showErrors(err);
          });
      } else {
        this.global_error.show = true;
        this.global_error.message = "The title field is required!";
      }
    },
    saveExtraDrink() {
      if (this.item.title) {
        let fd = new FormData();

        fd.append("title", this.item.title);

        fd.append("price", this.replaceComma(this.item.price));

        fd.append("tax", this.item.tax ? 24 : 14);

        fd.append("category_id", this.category_id);
        this.global_loading.show = true;
        this.$http
          .post(
            this.$hostname + "/items/" + this.item.id,
            fd,
            this.restaurant_token.config
          )
          .then((resp) => {
            this.successRequest();
            this.done();
          })
          .catch((err) => {
            this.showErrors(err);
          });
      } else {
        this.global_error.show = true;
        this.global_error.message = "The title field is required!";
      }
    },
    done() {
      this.fetchItems(this.category_id);
      this.closeModal("extra_drink_modal");
    },
  },
  props: {
    edit: false,
    category_id: 0,
    category_type: "",
    item: {
      id: 0,
      title: "",
      price: "",
    },
  },
};
</script>
<style scoped>
.modal {
  height: 280px;
  gap: 12px;
  justify-content: flex-start;
  padding: 0;
}
.question {
  background-color: #ffffff;
  height: 100%;
  border-radius: 16px 16px 0 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.tax-row {
  flex-direction: column;
  align-items: flex-start;
}

.tax {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
}
.tax input {
  width: 24px;
}

.tax-note {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #b3b3b3;
}

.tax label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #737373;
}

.fill {
  background-color: #5a187a;
  border: 1px solid #5a187a;
  max-width: 768px;
  width: 100%;
}
</style>
