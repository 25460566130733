<template>
  <div class="menu">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/'"></back-button>
        <div class="title">{{ $t("home.menu") }}</div>
        <call-waiter></call-waiter>
      </div>
    </div>
    <div class="content">
      <h4>{{ $t("cart.extras") }}</h4>
      <div class="categories">
        <router-link
          class="category"
          :style="[category.type != 'dish' ? { width: '100%' } : {}]"
          :to="'/extra-drink/' + category.type + '/' + category.id"
          v-for="category in restaurant_categories.data"
          :key="category.id"
          v-if="category.type != 'dish'"
          >{{ category.title }}
        </router-link>
      </div>
      <h4>{{ "Food categories" }}</h4>
      <div class="categories">
        <router-link
          class="category"
          :style="[category.type != 'dish' ? { width: '100%' } : {}]"
          :to="'/category/dish/' + category.id"
          v-for="category in restaurant_categories.data"
          :key="category.id"
          v-if="category.type == 'dish'"
          >{{ category.title }}
        </router-link>
      </div>
      <button @click="showModal('category_modal')" class="fill">
        {{ $t("restaurant_menu.add_new_category") }}
      </button>
    </div>
    <div id="blur_background" class="blur-bg">
      <new-category-modal></new-category-modal>
      <calls-modal></calls-modal>
    </div>
  </div>
</template>
<script>
import BackButton from "../../components/common/BackButton.vue";
import CallWaiter from "../../components/restaurant/CallWaiter.vue";
import NewCategoryModal from "../../components/restaurant/NewCategoryModal.vue";
import CallsModal from "../../components/restaurant/CallsModal.vue";

export default {
  components: {
    BackButton,
    CallWaiter,
    NewCategoryModal,
    CallsModal,
  },
  mounted() {
    // this.iOSBottomGapFixer(".content");

    this.fetchCategories();
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}
.content {
  height: 80%;
  /* overflow-y: scroll; */
  padding-top: 70px;
  padding-bottom: 120px;
}
.categories {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}
.category {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48%;
  border: 1px solid #8f93bf;
  border-radius: 8px;
  height: 48px;
  font-size: 14px;
  color: #412250;
}
button.fill {
  position: fixed;
  bottom: 68px;
  width: 92%;
  margin: auto;
  background-color: #5a187a;
  border: 1px solid #5a187a;
}

h4 {
  font-weight: 500;
  font-size: 18px;
  color: #b3b3b3;
  margin: 24px 0 16px;
}

@media screen and (min-width: 811px) {
  button {
    max-width: 768px;
  }
}
</style>
