import { getToken } from "firebase/messaging";
import { messaging } from "./firebase";

export const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey:
          "BN1F3RiK2CcueUSaDJ3ediuS4cTCNTxADHwassudCo5lWAKVOSshOznBhpT2QU5LHAdQYtqmMrXaMowBN3iacwA",
      });
      localStorage.notification_token = token
      console.log('hey', token);
      return token;
    } else {
      console.error("Permission not granted for notifications");
    }
  } catch (error) {
    console.error("An error occurred while retrieving token. ", error);
  }
};
