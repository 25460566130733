<template>
  <div class="navbar" id="navigation">
    <div class="container">
      <router-link to="/orders">
        <div class="btn">
          <div
            :class="[
              $route.name == 'Orders' ? 'active-orders' : 'deactive-orders',
              'icon',
            ]"
          >
            <span v-if="restaurant_new_orders.data.length" class="badge"
              >•</span
            >
          </div>
          <div
            :class="[
              $route.name == 'Orders' ? 'active-text' : 'deactive-text',
              'caption',
            ]"
          >
            {{ $t("cart.orders") }}
          </div>
        </div>
      </router-link>
      <router-link to="/reservation">
        <div class="btn">
          <div
            :class="[
              $route.name == 'Reservation'
                ? 'active-reservation'
                : 'deactive-reservation',
              'icon',
            ]"
          >
            <span v-if="restaurant_reservations.pending.length" class="badge"
              >•</span
            >
          </div>
          <div
            :class="[
              $route.name == 'Reservation' ? 'active-text' : 'deactive-text',
              'caption',
            ]"
          >
            {{ $t("navbar.reservation") }}
          </div>
        </div>
      </router-link>
      <router-link to="/history">
        <div class="btn">
          <div
            :class="[
              $route.name == 'History' ? 'active-history' : 'deactive-history',
              'icon',
            ]"
          ></div>
          <div
            :class="[
              $route.name == 'History' ? 'active-text' : 'deactive-text',
              'caption',
            ]"
          >
            {{ $t("home.history") }}
          </div>
        </div>
      </router-link>
      <router-link v-if="user_type === 'manager'" to="/">
        <div class="btn">
          <div
            :class="[
              management_routes.includes($route.name)
                ? 'active-settings'
                : 'deactive-settings',
              'icon',
            ]"
          ></div>
          <div
            :class="[
              management_routes.includes($route.name)
                ? 'active-text'
                : 'deactive-text',
              'caption',
            ]"
          >
            {{ $t("navbar.management") }}
          </div>
        </div>
      </router-link>
    </div>
    <audio controls id="notif">
      <source src="../../assets/restaurant/bell.mp3" type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  </div>
</template>
<script>
export default {
  mounted() {
    this.fetchUserId();
    this.fetchOrders();
    this.fetchReservations();

    let check_notification = window.setInterval(() => {
      if (!localStorage.restaurant_token) {
        clearInterval(check_notification);
      } else {
        this.checkNotification();
        // this.fetchCalls();
      }
    }, 4000);

    Pusher.logToConsole = false;

    const vm = this;


    let order_channel = this.$pusher.subscribe(
      "orders-" + this.decodeUserIdByToken()
    );

    order_channel.bind("orders", function (data) {
      vm.fetchOrders();

      document.getElementById("notif").play();
      // const notification2 = setTimeout(() => {
      //   document.getElementById("notif").play();
      // }, 3000);

      // const notification3 = setTimeout(() => {
      //   document.getElementById("notif").play();
      // }, 6000);

      // var notification = new Notification("You have a new notification!");
    });


    let reservation_channel = this.$pusher.subscribe(
      "reservations-" + this.decodeUserIdByToken()
    );

    reservation_channel.bind("reservations", function (data) {
      if (data.id) {
        vm.fetchReservations();

        // var notification = new Notification("You have a new notification!");
        
        document.getElementById("notif").play();

        // const notification2 = setTimeout(() => {
        //   document.getElementById("notif").play();
        // }, 3000);

        // const notification3 = setTimeout(() => {
        //   document.getElementById("notif").play();
        // }, 6000);
      }
    });
  },
  methods: {
    fetchUserId() {
      // this.global_loading.show = true;
      this.$http
        .get(
          this.$hostname + "/get-user-restaurant",
          this.restaurant_token.config
        )
        .then((resp) => {
          // this.successRequest();
          localStorage.your_identifier = resp.data.user_id;

          this.$store.dispatch("setUserId", {
            user: resp.data.user_id,
          });

          this.user_type = resp.data.restaurant_user_type;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    checkNotification() {
      if (
        this.restaurant_new_orders.data.length ||
        this.restaurant_calls.data.length ||
        this.restaurant_reservations.pending.length
      ) {
        document.getElementById("notif").play();

        // var notification = new Notification("You have a new notification!");
      } else {
        document.getElementById("notif").pause();
      }
      // this.$http
      //   .get(this.$hostname + "/has-notification", this.restaurant_token.config)
      //   .then((resp) => {
      //   })
      //   .catch((err) => {
      //     this.showErrors(err);
      //   });
    },
  },
  data() {
    return {
      management_routes: [
        "Home",
        "Menu",
        "Info",
        "Rate",
        "Category",
        "ExtraDrink",
        "Item",
        "Voucher",
        "Settings",
        "Profile",
        "RestaurantInfo",
        "Delivery",
        "Services",
        "SocialsLinks",
        "Lunch",
        "LunchSettings",
      ],
      user_type: "",
      has_order: false,
    };
  },
};
</script>
<style scoped>
.navbar {
  position: fixed;
  bottom: 0%;
  width: 100%;
  background: rgba(245, 246, 247, 0.7);
  backdrop-filter: blur(50px);
}
.container {
  max-width: 768px;
  margin: auto;
  width: 100%;
  padding: 8px 0px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
a {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.btn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
  align-items: center;
  width: 100%;
}
.caption {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.3px;
  height: 14px;
}

.deactive-history {
  background-image: url("../../assets/restaurant/navbar/history.svg");
}
.active-history {
  background-image: url("../../assets/restaurant/navbar/history_on.svg");
}

.deactive-reservation {
  background-image: url("../../assets/restaurant/navbar/reservation.svg");
  background-size: 20px;
}
.active-reservation {
  background-image: url("../../assets/restaurant/navbar/reservation_on.svg");
  background-size: 20px;
}

.deactive-orders {
  background-image: url("../../assets/restaurant/navbar/orders.svg");
}
.active-orders {
  background-image: url("../../assets/restaurant/navbar/orders_on.svg");
}

.active-reservation,
.deactive-reservation,
.deactive-orders,
.active-orders {
  position: relative;
}
.deactive-settings {
  background-image: url("../../assets/restaurant/navbar/settings.svg");
}
.active-settings {
  background-image: url("../../assets/restaurant/navbar/settings_on.svg");
}

#notif {
  position: fixed;
  bottom: -400px;
}
</style>
