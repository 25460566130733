<template>
  <div id="lunch_day_modal" class="modal">
    <div class="question">
      <button @click="deleteConfirm()" class="icon delete"></button>

      <div class="input">
        <label class="active" for="title">{{ $t("information.title") }}</label>
        <input
          class="active"
          type="text"
          id="title"
          v-model="lunch.processing.title"
        />
      </div>
      <div class="input">
        <label class="active" for="desc">{{
          $t("restaurant_menu.description")
        }}</label>
        <textarea
          class="active"
          type="text"
          id="desc"
          v-model="lunch.processing.description"
        ></textarea>
      </div>
      <div class="input">
        <label class="active" for="price">{{ $t("history.price") }}</label>
        <input
          class="active"
          type="text"
          id="price"
          v-model="lunch.processing.price"
        />
        <span>€</span>
      </div>
      <button @click="save()" class="fill">
        {{ $t("lunch.add_lunch") }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    save() {
      this.global_loading.show = true;
      this.lunch.processing.price = this.replaceComma(
        this.lunch.processing.price
      );

      if (this.lunch.processing.id) {
        this.$http
          .patch(
            this.$hostname + "/lunch-days/" + this.lunch.processing.id,
            this.lunch.processing,
            this.restaurant_token.config
          )
          .then((resp) => {
            this.successRequest();
            this.fetchLunchDays(this.lunch.processing.lunch_week_id);
            this.closeModal("lunch_day_modal");
          })
          .catch((err) => {
            this.showErrors(err);
          });
      } else {
        this.$http
          .post(
            this.$hostname + "/lunch-days",
            this.lunch.processing,
            this.restaurant_token.config
          )
          .then((resp) => {
            this.successRequest();
            this.fetchLunchDays(this.lunch.processing.lunch_week_id);
            this.closeModal("lunch_day_modal");
          })
          .catch((err) => {
            this.showErrors(err);
          });
      }
    },
    deleteConfirm() {
      if (confirm("Haluatko varmasti poistaa sen?")) {
        this.deleteLunch();
        this.closeModal("lunch_day_modal");
      }
    },
    deleteLunch() {
      this.$http
        .delete(
          this.$hostname + "/lunch-days/" + this.lunch.processing.id,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          this.fetchLunchDays(this.lunch.processing.lunch_week_id);
          this.closeModal("lunch_day_modal");
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
  },
};
</script>
<style scoped>
.modal {
  height: 350px;
  gap: 12px;
  justify-content: flex-start;
  padding: 0;
}
.question {
  background-color: #ffffff;
  height: 100%;
  border-radius: 16px 16px 0 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
