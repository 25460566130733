<template>
  <div class="info">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/profile'"></back-button>
        <div class="title">{{ $t("home.restaurant_info") }}</div>
        <call-waiter></call-waiter>
      </div>
    </div>
    <div class="content">
      <div class="photos">
        <div class="photo_input">
          <label
            v-if="main_photo.url"
            @click.prevent="deletePhoto(main_photo.id, 0, main_photo.url)"
            for="main_photo"
            :style="[
              main_url
                ? { 'background-image': 'url(' + main_url + ')' }
                : { 'background-image': 'url(' + main_photo.url + ')' },
            ]"
          ></label>
          <label v-else for="main_photo" id="upload"></label>

          <div
            @click.prevent="deletePhoto(main_photo.id, 0, main_photo.url)"
            class="trash"
            v-if="main_photo.url"
          ></div>
          <span v-else>
            {{ $t("information.main_pic") }}
          </span>

          <input
            @change="setPhoto"
            type="file"
            name="main_photo"
            id="main_photo"
          />
        </div>
        <div class="photo_input">
          <label
            v-if="second_photo.url"
            for="photo"
            @click.prevent="deletePhoto(second_photo.id, 1, second_photo.url)"
            :style="[
              second_url
                ? { 'background-image': 'url(' + second_url + ')' }
                : { 'background-image': 'url(' + second_photo.url + ')' },
            ]"
          ></label>
          <label v-else for="photo" id="upload"></label>

          <div
            @click.prevent="deletePhoto(second_photo.id, 1, second_photo.url)"
            class="trash"
            v-if="second_photo.url"
          ></div>
          <span v-else>
            {{ $t("information.second_pic") }}
          </span>

          <input
            @change="setPhoto"
            type="file"
            name="photo"
            id="photo"
            hidden
          />
        </div>
        <div class="photo_input">
          <label
            v-if="third_photo.url"
            for="photo"
            @click.prevent="deletePhoto(third_photo.id, 2, third_photo.url)"
            :style="[
              third_url
                ? { 'background-image': 'url(' + third_url + ')' }
                : { 'background-image': 'url(' + third_photo.url + ')' },
            ]"
          ></label>
          <label v-else for="photo" id="upload"></label>
          <div
            @click.prevent="deletePhoto(third_photo.id, 2, third_photo.url)"
            class="trash"
            v-if="third_photo.url"
          ></div>

          <span v-else>
            {{ $t("information.third_pic") }}
          </span>
          <input
            @change="setPhoto"
            type="file"
            name="photo"
            id="photo"
            hidden
          />
        </div>
        <div class="photo_input">
          <label
            v-if="fourth_photo.url"
            for="photo"
            @click.prevent="deletePhoto(fourth_photo.id, 3, fourth_photo.url)"
            :style="[
              fourth_url
                ? { 'background-image': 'url(' + fourth_url + ')' }
                : { 'background-image': 'url(' + fourth_photo.url + ')' },
            ]"
          ></label>
          <label v-else for="photo" id="upload"></label>
          <div
            @click.prevent="deletePhoto(fourth_photo.id, 3, fourth_photo.url)"
            class="trash"
            v-if="fourth_photo.url"
          ></div>

          <span v-else>
            {{ $t("information.fourth_pic") }}
          </span>

          <input
            @change="setPhoto"
            type="file"
            name="photo"
            id="photo"
            hidden
          />
        </div>
      </div>

      <div class="form">
        <v-text-field
          v-model="title"
          filled
          :label="$t('information.title')"
        ></v-text-field>

        <v-textarea
          v-model="banner_text"
          filled
          maxlength="100"
          rows="3"
          :label="$t('info.banner_text') + ' (' + maxCharacters + '/100)'"
        ></v-textarea>

        <v-textarea
          v-model="address"
          filled
          rows="2"
          :label="$t('info.address')"
        ></v-textarea>

        <v-text-field
          v-model="phone_number"
          filled
          :label="$t('information.phone_number')"
        ></v-text-field>

        <v-textarea
          v-model="about"
          filled
          rows="4"
          :label="$t('home.info')"
        ></v-textarea>

     
      </div>

      <div class="hours">
        <button @click="showModal('working_hours_modal')" class="light">
          {{ $t("information.choose_working") }}
        </button>
      </div>

      <button @click="done()" class="fill">{{ $t("information.save") }}</button>
    </div>
    <div id="blur_background" class="blur-bg">
      <working-hours-modal
        :opening_times="opening_times"
        @opening_times="openingTimes"
      ></working-hours-modal>
      <calls-modal></calls-modal>
      <delete-photo-modal
        :index="selected_photo_index"
        :photo_id="selected_photo_id"
        :url="selected_photo_url"
        @deleted_photo="deletedPhoto"
      ></delete-photo-modal>
    </div>
  </div>
</template>
<script>
import BackButton from "../../../components/common/BackButton.vue";
import CallsModal from "../../../components/restaurant/CallsModal.vue";
import CallWaiter from "../../../components/restaurant/CallWaiter.vue";
import DeletePhotoModal from "../../../components/restaurant/DeletePhotoModal.vue";
import WorkingHoursModal from "../../../components/restaurant/WorkingHoursModal.vue";

export default {
  components: {
    BackButton,
    CallWaiter,
    WorkingHoursModal,
    CallsModal,
    DeletePhotoModal,
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.global_loading.show = true;
      this.$http
        .get(this.$hostname + "/info", this.restaurant_token.config)
        .then((resp) => {
          this.successRequest();
          this.title = resp.data.info[0].title;
          this.banner_text = resp.data.info[0].banner_text;
          this.address = resp.data.info[0].address;
          this.phone_number = resp.data.info[0].phone_number;
          this.about = resp.data.info[0].about;
          this.reservation_note = resp.data.info[0].reservation_note;
          this.opening_times = resp.data.info[0].opening_times;
          this.default_reservation_length =
            resp.data.info[0].default_reservation_length;

          this.main_photo = resp.data.info[0].photos[0]
            ? resp.data.info[0].photos[0]
            : {};

          this.second_photo = resp.data.info[0].photos[1]
            ? resp.data.info[0].photos[1]
            : {};

          this.third_photo = resp.data.info[0].photos[2]
            ? resp.data.info[0].photos[2]
            : {};
          this.fourth_photo = resp.data.info[0].photos[3]
            ? resp.data.info[0].photos[3]
            : {};
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    save() {
      let fd = new FormData();

      fd.append("title", this.title);
      fd.append("banner_text", this.banner_text ? this.banner_text : "");
      fd.append("address", this.address ? this.address : "");
      fd.append("phone_number", this.phone_number ? this.phone_number : "");
      fd.append("about", this.about ? this.about : "");
      fd.append(
        "default_reservation_length",
        this.default_reservation_length ? this.default_reservation_length : 1
      );
      fd.append(
        "reservation_note",
        this.reservation_note ? this.reservation_note : ""
      );

      fd.append("opening_times", JSON.stringify(this.opening_times));

      this.global_loading.show = true;
      this.$http
        .post(this.$hostname + "/info", fd, this.restaurant_token.config)
        .then((resp) => {
          this.successRequest();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    done() {
      this.save();
      this.backToHome();
    },
    backToHome() {
      this.$router.push("/info");
    },
    setPhoto(e) {
      let fd = new FormData();

      this.photo = e.target.files[0] || e.dataTransfer.files[0];

      fd.append("photo", this.photo);
      fd.append("restraunt_title", this.title);

      this.global_loading.show = true;
      this.$http
        .post(this.$hostname + "/set-photo", fd, this.restaurant_token.config)
        .then((resp) => {
          this.fetch();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    deletePhoto(photo_id, index, url) {
      this.selected_photo_id = photo_id;

      this.selected_photo_index = index;

      this.selected_photo_url = url;

      this.showModal("delete_photo_modal");
    },
    deletedPhoto(photo_id, index) {
      switch (index) {
        case 0:
          this.main_photo = {};

          break;
        case 1:
          this.second_photo = {};

          break;
        case 2:
          this.third_photo = {};

          break;
        case 3:
          this.fourth_photo = {};

          break;

        default:
          break;
      }

      this.fetch();
    },
    openingTimes(val) {
      this.opening_times = val;
      this.save();
    },
  },
  computed: {
    maxCharacters() {
      return this.banner_text ? this.banner_text.length : 0;
    },
  },
  data() {
    return {
      photo: "",
      main_photo: {},
      main_url: "",
      second_photo: {},
      second_url: "",
      third_photo: {},
      third_url: "",
      fourth_photo: {},
      fourth_url: "",
      title: "",
      phone_number: "",
      address: "",
      about: "",
      reservation_note: "",
      banner_text: "",
      opening_times: [],
      selected_photo_id: 0,
      selected_photo_url: "",
      selected_photo_index: 0,
      default_reservation_length: 1,
    };
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}

.content {
  height: 100vh;
  overflow-y: scroll;
  padding: 78px 16px 96px;
}
.form {
  padding: 16px 0 0;
}

.photos {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #f0edf2;
}

.photo_input {
  width: 48.5%;
  padding: 0;
  position: relative;
}

.photo_input label {
  width: 100%;
  min-height: 100%;
  background-size: cover;
}

.photo_input span {
  position: absolute;
  bottom: 0;
}

.phone_number label {
  width: 44%;
}

label.banner_text {
  width: 50%;
  min-width: 42%;
}
.phone_number input {
  width: 56%;
}
textarea {
  width: 65%;
}
textarea#about {
  height: 157px;
  width: 75%;
}

.hours {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #f0edf2;
}
.hours button {
  font-size: 14px;
  width: 100%;
  border-radius: 8px;
  height: 42px;
}

h4 {
  padding: 16px 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
}

.trash {
  background-image: url("../../../assets/restaurant/home/trash.svg");
  background-size: 40px;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  background-blend-mode: multiply;
  background-color: #f0edf2b4;
}

button.fill {
  min-height: 48px;
  margin-top: 12px;
}
</style>
