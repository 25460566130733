<template>
  <div class="modal" id="table_combination_modal">
    <div class="question">
      <div class="options">
        <div class="tables">
          <div
            class="option"
            v-for="table in tables"
            :key="table.id"
            @click.prevent="setTables(table.id)"
          >
            <input
              type="checkbox"
              name="tables"
              :id="table.name + table.id"
              :checked="checked_tables.includes(table.id)"
            />
            <label :for="table.name + table.id">{{ table.name }}</label>
          </div>
        </div>
        <div class="input">
          <label class="deactive" for="table-number">{{
            $t("reserve.table_number")
          }}</label>
          <input
            class="active"
            type="text"
            id="table-number"
            v-model="table_combination.name"
          />
        </div>
        <div class="input">
          <label class="deactive" for="capacity">{{
            $t("reserve.capacity")
          }}</label>
          <input
            class="active"
            type="text"
            id="capacity"
            v-model="table_combination.capacity"
          />
        </div>
        <div class="input">
          <label class="deactive" for="minimum">{{
            $t("reserve.minimum")
          }}</label>
          <input
            class="active"
            type="text"
            id="minimum"
            v-model="table_combination.minimum"
          />
        </div>

        <div class="input">
          <label class="deactive" for="priority">{{ "Priority" }}</label>
          <input
            class="active"
            type="text"
            id="priority"
            v-model="table_combination.priority"
          />
        </div>
      </div>
      <button @click="submit()" class="fill">
        {{ $t("information.save") }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  watch: {
    $route(to, from) {
      if (to.query.table_combination) {
        this.checked_tables = [];
        if (this.table_combination && this.table_combination.tables) {
          let selected_tables = JSON.parse(this.table_combination.tables);
          selected_tables.map((ele) => {
            this.setTables(ele);
          });
        }
      }
      if (from.query.table_combination) {
        this.checked_tables = [];
      }
    },
  },
  methods: {
    setTables(table) {
      const index = this.checked_tables.indexOf(table);

      if (index > -1) {
        this.checked_tables.splice(index, 1);
      } else {
        this.checked_tables.push(table);
      }
    },
    submit() {
      this.table_combination.tables = this.checked_tables;
      if (this.table_combination.id) {
        this.$http
          .patch(
            this.$hostname + "/table-combinations/" + this.table_combination.id,
            this.table_combination,
            this.restaurant_token.config
          )
          .then((resp) => {
            this.successRequest();
            this.checked_tables = [];
            this.$emit("closed_model", true);
            this.closeModal("table_combination_modal");
          })
          .catch((err) => {
            this.showErrors(err);
          });
      } else {
        this.$http
          .post(
            this.$hostname + "/table-combinations",
            this.table_combination,
            this.restaurant_token.config
          )
          .then((resp) => {
            this.successRequest();
            this.checked_tables = [];
            this.$emit("closed_edit_modal", true);
            this.closeModal("table_combination_modal");
          })
          .catch((err) => {
            this.showErrors(err);
          });
      }
    },
  },

  data() {
    return {
      checked_tables: [],
    };
  },
  props: {
    table_combination: {
      tables: [],
    },
    tables: [],
  },
};
</script>
<style scoped>
.modal {
  height: 560px;
  gap: 12px;
  justify-content: flex-start;
  padding: 0;
}
.question {
  background-color: #ffffff;
  height: 100%;
  border-radius: 16px 16px 0 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;
}
.options {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
}

.fill {
  background-color: #5a187a;
  border: 1px solid #5a187a;
  max-width: 768px;
  min-height: 40px;
  width: 100%;
  border-radius: 8px;
}

.tables {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.tables .option {
  width: 23%;
}
.tables label {
  background-color: #ffffff;
  border: 1px solid #f0edf2;
  border-radius: 8px;
  width: 31%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #737373;
  font-weight: 400;
  font-size: 14px;
}

.tables label {
  width: 100%;
}

.tables input[type="checkbox"]:checked + label {
  border: 1px solid #5a187a;
  color: #5a187a;
}

.tables input[type="checkbox"] {
  appearance: none;
  display: none;
  background-image: none;
}
</style>
