import { render, staticRenderFns } from "./LunchDay.vue?vue&type=template&id=300f1e35&scoped=true&"
import script from "./LunchDay.vue?vue&type=script&lang=js&"
export * from "./LunchDay.vue?vue&type=script&lang=js&"
import style0 from "./LunchDay.vue?vue&type=style&index=0&id=300f1e35&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "300f1e35",
  null
  
)

export default component.exports