<template>
  <div class="info-page">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/'"></back-button>
        <div class="title">{{ $t("home.manage_info") }}</div>
        <call-waiter></call-waiter>
      </div>
    </div>
    <div class="content">
     
    </div>

    <div id="blur_background" class="blur-bg">
      <calls-modal></calls-modal>
    </div>
  </div>
</template>
<script>
import BackButton from "../../components/common/BackButton.vue";
import CallsModal from "../../components/restaurant/CallsModal.vue";
import CallWaiter from "../../components/restaurant/CallWaiter.vue";

export default {
  components: {
    BackButton,
    CallWaiter,
    CallsModal,
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}

.content {
  height: 100vh;
  overflow-y: scroll;
  padding: 78px 16px 96px;
  gap: 8px;
}

.icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
}



.left .icon {
  background-color: #ffffff;
}
</style>
