<template>
  <button @click="callModal()" class="btn call">
    <div :class="[checkHasCall ? 'animation-blazer' : '', 'blazer']"></div>
    <div :class="[checkHasCall ? 'animation-call' : '', 'icon', 'call']"></div>
  </button>
  <!-- <audio
      muted="muted"
      id="notif"
      src="../../assets/restaurant/bell.mp3"
      hidden
    ></audio> -->
</template>
<script>
export default {
  mounted() {
    Pusher.logToConsole = false;
    this.fetchCalls();

    // let callwaiter = window.setInterval(() => {
    //   if (!localStorage.restaurant_token) {
    //     clearInterval(callwaiter);
    //   } else {
    //     this.fetchCalls();
    //   }
    // }, 10000);

    const vm = this;

    let channel = this.$pusher.subscribe("calls-" + this.decodeUserIdByToken());

    channel.bind("calls", function (data) {
      if (data.call) {
        vm.fetchCalls();

        vm.has_call = true;

        document.getElementById("notif").play();

        // const notification2 = setTimeout(() => {
        //   document.getElementById("notif").play();
        // },3000);

        // const notification3 = setTimeout(() => {
        //   document.getElementById("notif").play();
        // }, 6000);

        var notification = new Notification("New call has arrived!");
      }
    });
  },
  methods: {
    callModal() {
      this.fetchCalls();
      this.showModal("calls_modal");
      if (!this.played) {
        document.getElementById("notif").play();
        this.played = true;
      }
    
    },
  },
  computed: {
    checkHasCall() {
      if (this.restaurant_calls.data.length) {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      has_call: false,
      played: false,
    };
  },
};
</script>
<style scoped>
.btn {
  width: 40px;
  height: 40px;
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
  /* background-color: #ffeeeb; */
  border-radius: 8px;
  display: flex;
  align-items: center;
}
button.call {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 14px;
  height: 40px;
  width: 84px;
  background-color: #f5f6f7;
  padding: 0;
  /* background-color: #ffeeeb; */
}

button.call .call {
  position: absolute;
  width: 40px;
  background-image: url("../../assets/restaurant/home/bell.svg");
}
.animation-call {
  -webkit-animation: iconreverse 1.5s 0.2s ease-in-out infinite;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: iconreverse 1.5s 0.2s ease-in-out infinite;
  -moz-transform-origin: 50% 50%;
  animation: iconreverse 1.5s 0.2s ease-in-out infinite;
  transform-origin: 50% 50%;
}

.blazer {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  transform: scale(0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5a187a;
  /* background-blend-mode: color-dodge; */
}

.animation-blazer {
  -webkit-animation: heart 1.5s 0.2s ease-in-out infinite;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: heart 1.5s 0.2s ease-in-out infinite;
  -moz-transform-origin: 50% 50%;
  animation: heart 1.5s 0.2s ease-in-out infinite;
  transform-origin: 50% 50%;
}

@keyframes heart {
  0% {
    border-radius: 50%;
    transform: scale(0, 0);
  }

  50% {
    border-radius: 8px;
    transform: scale(1, 1);
  }

  100% {
    border-radius: 50%;
    transform: scale(0, 0);
  }
}

@keyframes iconreverse {
  0% {
    background-image: url("../../assets/restaurant/home/bell.svg");
  }

  20% {
    background-image: url("../../assets/restaurant/home/bell.svg");
  }

  40% {
    background-image: url("../../assets/restaurant/home/bell_rev.svg");
  }

  60% {
    background-image: url("../../assets/restaurant/home/bell_rev.svg");
  }

  80% {
    background-image: url("../../assets/restaurant/home/bell.svg");
  }

  100% {
    background-image: url("../../assets/restaurant/home/bell.svg");
  }
}
</style>
