var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-reservation",class:{
    table_reservation_2: _vm.table_reservation.capacity == 2,
    table_reservation_6: _vm.table_reservation.capacity == 3,
    table_reservation_4: _vm.table_reservation.capacity == 4,
    table_reservation_5: _vm.table_reservation.capacity == 5,
    table_reservation_6: _vm.table_reservation.capacity == 6,
    table_reservation_7: _vm.table_reservation.capacity == 7,
    table_reservation_8: _vm.table_reservation.capacity == 8,
  },style:([
    _vm.reservationStarted == 2
      ? { backgroundColor: '#db411a' }
      : _vm.reservationStarted == 1
      ? { backgroundColor: '#f0ec0c' }
      : _vm.reservationStarted == 3
      ? { backgroundColor: '#f29a57' }
      : { backgroundColor: '#6ede75' } ]),on:{"click":function($event){return _vm.openReservationModal(_vm.currentReservation)}}},[_c('div',{staticClass:"table"},[_vm._v(" "+_vm._s(_vm.table_reservation.name)+" ")]),_c('div',{staticClass:"status"},[_vm._v(" "+_vm._s(_vm.reservationStarted == 0 ? "Vapaa" : _vm.reservationStarted == 1 ? "Varaus" : _vm.reservationStarted == 3 ? "Myöhässä" : "Varattu")+" ")]),_c('div',{staticClass:"people"},[_vm._v(" "+_vm._s(_vm.reservationStarted == 1 || _vm.reservationStarted == 3 ? _vm.formatTime(_vm.currentReservation.reserved_at) : "(" + _vm.table_reservation.capacity + ")")+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }