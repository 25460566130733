<template>
  <div class="history">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/lunch'"></back-button>
        <div class="title">{{ $t("home.lunch") }}</div>
        <call-waiter></call-waiter>
      </div>
    </div>
    <div class="content">
      <div v-if="lunch.weekdata" class="lunch-week-header">
        <p class="week-number">Viikko {{ getWeekNumber(lunch.weekdata.start_week) }}</p>
        <p class="week-range">{{ formatDate(lunch.weekdata.start_week) }} - {{ formatDate(lunch.weekdata.end_week) }}</p>
      </div>
      <div class="days">
        <div class="day" v-for="day in week_days" :key="day">
          <h3>
            {{ $t("lunch." + day) }}
          </h3>
          <div class="lunch-area">
            <div class="lunches">
              <div
                class="lunch"
                v-for="(lunch_day, index) in lunch.data[day]"
                :key="index"
                @click="openLunchModal(lunch_day, day)"
              >
                <div class="title">{{ lunch_day.title }}</div>
                <div class="price">{{ formatPrice(lunch_day.price) }}</div>
              </div>
              <button @click="openLunchModal({}, day)" class="lunch-fill">
                {{ $t("lunch.add_lunch") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="blur_background" class="blur-bg">
      <calls-modal></calls-modal>
      <lunch-day-modal></lunch-day-modal>
    </div>
  </div>
</template>
<script>
import BackButton from "../../components/common/BackButton.vue";
import CallWaiter from "../../components/restaurant/CallWaiter.vue";
import CallsModal from "../../components/restaurant/CallsModal.vue";
import LunchDayModal from "../../components/restaurant/lunch/LunchDayModal.vue";

export default {
  components: {
    BackButton,
    CallWaiter,
    CallsModal,
    LunchDayModal,
  },
  props: {
    id: 0,
  },
  mounted() {
    this.fetchLunchDays(this.id);
  },
  methods: {
    openLunchModal(lunch, day) {
      lunch.day = day;
      lunch.lunch_week_id = this.id;
      this.lunch.processing = lunch;
      this.showModal("lunch_day_modal");
    },
  },
  data() {
    return {
      lunch_days: [],
      week_days: [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ],
    };
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}
.content {
  overflow-y: scroll;
  padding: 84px 16px 72px;
}
.lunch-week-header {
  background: #408CFF;
  color: #fff;
  padding: 12px 16px;
  border-radius: 10px 10px 0 0;
}
.week-number {
  font-weight: 600;
  margin-bottom: 6px;
}
.week-range {
  font-size: 14px;
}
.days {
  background-color: #f6f6f6;
  border-radius: 0 0 10px 10px;
  padding: 12px;
}
.day {
  margin: 12px 0;
}
h3 {
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 4px;
}
.lunch-area {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #e3e3e3;
  padding: 4px 10px;
}
.lunch-fill {
  background: #408CFF;
  border: none;
  border-radius: 10px;
  margin-top: 8px;
  padding: 8px;
  width: 150px;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  align-self: flex-end;
}
.lunch {
  display: flex;
  justify-content: space-between;
  margin: 6px 0;
  background-color: #f6f6f6;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;
}
.price {
  min-width: 60px;
  text-align: right;
  margin-left: 12px;
}
</style>
