<template>
  <div class="history">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/reservation'"></back-button>

        <div class="title">{{ $t("menu.make_reservation") }}</div>
        <call-waiter></call-waiter>
      </div>
    </div>
    <div class="content">
      <div class="form">
        <div class="input">
          <label class="deactive" for="fullname">{{
            $t("reserve.full_name")
          }}</label>
          <input
            class="active"
            type="text"
            id="fullname"
            v-model="reservation.full_name"
          />
        </div>

        <div class="input">
          <label class="deactive" for="people">{{
            $t("reserve.people")
          }}</label>
          <input
            class="active"
            type="number"
            id="people"
            v-model="reservation.people"
          />
        </div>

        <div class="input">
          <label class="deactive" for="email">{{
            $t("registration.email")
          }}</label>
          <input
            class="active"
            type="email"
            id="email"
            v-model="reservation.email"
          />
        </div>

        <div class="input">
          <label class="deactive" for="phone_number">{{
            $t("information.phone_number")
          }}</label>
          <input
            class="active"
            type="text"
            id="phone_number"
            v-model="reservation.phone_number"
          />
        </div>

        <div class="input">
          <label class="deactive" for="date">{{ "Date" }}</label>
          <input
            class="active"
            type="date"
            id="date"
            v-model="reservation_date"
          />
        </div>

        <div class="input">
          <label class="deactive" for="time">{{ "Time" }}</label>
          <input
            class="active"
            type="time"
            id="time"
            v-model="reservation_time"
          />
        </div>

        <div class="input">
          <label class="deactive" for="duration">{{ "Duration" }}</label>
          <input
            class="active"
            type="number"
            id="duration"
            v-model="duration"
          />
        </div>

        <div class="input">
          <label class="deactive desc" for="message">{{
            $t("reserve.message")
          }}</label>
          <textarea
            class="active"
            type="text"
            id="message"
            v-model="reservation.message"
          ></textarea>
        </div>

        <div class="input">
          <label class="deactive" for="table">{{ "Table" }}</label>
          <select
            name="table"
            v-model="selected_table"
            @change="cleanTableCombo()"
            id="table"
          >
            <option v-for="table in tables" :key="table.id" :value="table.id">
              {{ table.name }}
            </option>
          </select>
        </div>

        <div class="input">
          <label class="deactive" for="table_combo">{{
            "Or Table Combination"
          }}</label>
          <select
            name="table_combo"
            v-model="selected_table_combination"
            id="table_combo"
            @change="cleanTable()"
          >
            <option
              v-for="table_combo in table_combinations"
              :key="table_combo.id"
              :value="table_combo.id"
            >
              {{ table_combo.name }}
            </option>
          </select>
        </div>
      </div>
      <button @click="submit()" class="fill">
        {{ $t("information.save") }}
      </button>
    </div>
    <div id="blur_background" class="blur-bg">
      <calls-modal></calls-modal>
    </div>
  </div>
</template>
<script>
import CallWaiter from "../../components/restaurant/CallWaiter.vue";
import CallsModal from "../../components/restaurant/CallsModal.vue";
import BackButton from "../../components/common/BackButton.vue";

export default {
  components: {
    CallWaiter,
    BackButton,
    CallsModal,
  },
  mounted() {
    this.fetch();
    this.fetchTables();
    this.fetchTableCombos();
  },
  methods: {
    fetch() {
      this.global_loading.show = true;
      this.$http
        .get(
          this.$hostname + "/restaurant/reservations/" + this.id,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          this.reservation = resp.data.reservation;
          this.displayDate;
          this.displayTime;
          const start = new Date(resp.data.reservation.reserved_at);
          const end = new Date(resp.data.reservation.reserved_end);

          // Calculate the duration in milliseconds
          const durationInMilliseconds = end - start;

          // Convert the duration to minutes or other units if needed
          this.duration = durationInMilliseconds / (1000 * 60);

          if (resp.data.reservation.table_id) {
            this.selected_table = resp.data.reservation.table_id;
          }
          if (resp.data.reservation.table_combination_id) {
            this.selected_table_combination =
              resp.data.reservation.table_combination_id;
          }
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    fetchTables() {
      this.global_loading.show = true;
      this.$http
        .get(this.$hostname + "/tables", this.restaurant_token.config)
        .then((resp) => {
          this.successRequest();
          this.tables = resp.data.result;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    fetchTableCombos() {
      this.global_loading.show = true;
      this.$http
        .get(
          this.$hostname + "/table-combinations",
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          this.table_combinations = resp.data.result;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    submit() {
      let data = {
        full_name: this.reservation.full_name,
        email: this.reservation.email,
        message: this.reservation.message,
        status: this.reservation.status,
        phone_number: this.reservation.phone_number,
        people: this.reservation.people,
        datetime: this.reservation_date + " " + this.reservation_time,
        duration: this.duration,
        table_id: this.selected_table,
        table_combination_id: this.selected_table_combination,
        source_id: 2,
      };
      this.global_loading.show = true;
      this.$http
        .patch(
          this.$hostname + "/reservations/" + this.id,
          data,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          this.$router.push(`/reservation`);
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    cleanTableCombo() {
      this.selected_table_combination = null;
    },
    cleanTable() {
      this.selected_table = null;
    },
  },
  computed: {
    displayDate() {
      const reservation_datetime = new Date(
        Date.parse(this.reservation.reserved_at)
      );
      this.reservation_date = reservation_datetime.toISOString().split("T")[0];
    },
    displayTime() {
      const reservation_datetime = new Date(
        Date.parse(this.reservation.reserved_at)
      );
      const hours = String(reservation_datetime.getHours()).padStart(2, "0");
      const minutes = String(reservation_datetime.getMinutes()).padStart(
        2,
        "0"
      );
      const seconds = String(reservation_datetime.getSeconds()).padStart(
        2,
        "0"
      );

      this.reservation_time = `${hours}:${minutes}:${seconds}`;
    },
  },
  data() {
    return {
      tables: [],
      table_combinations: [],
      selected_table: 0,
      selected_table_combination: 0,
      full_name: "",
      email: "",
      phone_number: "",
      date: "",
      time: "",
      message: "",
      duration: 0,
      people: 1,
      reservation: {},
      reservation_date: "",
      reservation_time: "",
    };
  },
  props: {
    id: "",
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}
.content {
  height: 100vh;
  overflow-y: scroll;
  gap: 8px;
  justify-content: space-between;
}

select {
  width: 100%;
}
</style>
