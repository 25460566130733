<template>
  <div id="walkin_reservation_modal" class="modal">
    <div class="question">
      <div class="input-group">
        <label
          v-for="table in table_reservations.tables"
          :key="table.id"
          class="radio-label"
        >
          <input
            type="radio"
            name="table"
            :value="table.id"
            v-model="table_id"
            class="radio-input"
            @change="clearTableCombination"
          />
          <span class="radio-custom">{{ table.name }}</span>
        </label>
      </div>

      <div class="input-group">
        <label
          v-for="table_combination in table_reservations.table_combinations"
          :key="table_combination.id"
          class="radio-label"
        >
          <input
            type="radio"
            name="table_combination"
            :value="table_combination.id"
            v-model="table_combination_id"
            class="radio-input"
            @change="clearTable"
          />
          <span class="radio-custom">{{ table_combination.name }}</span>
        </label>
      </div>

      <button
        @click="createReservation()"
        :class="[valid ? '' : 'invalid', 'fill']"
      >
        {{ $t("information.save") }}
        <!-- {{ valid }} -->
      </button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selected_table: 0,
      reservation_date: "",
      reservation_time: "",
      table_id: null,
      table_combination_id: null,
    };
  },
  computed: {
    valid() {
      return true;
    },
  },
  methods: {
    clearTableCombination() {
      if (this.table_id) {
        this.table_combination_id = null;
      }
    },
    clearTable() {
      if (this.table_combination_id) {
        this.table_id = null;
      }
    },
    createReservation() {
      const now = new Date();
      const localTime = new Intl.DateTimeFormat("en-US", {
        timeZone: "Europe/Helsinki",
        timeStyle: "medium",
        dateStyle: "long",
      }).format(now);

      let data = {
        datetime: localTime,
        status: "arrived",
        full_name: "Walk-in",
        email: "service@nordantia.com",
        message: "Walk-in",
        phone_number: "",
        people: 4,
        table_id: this.table_id,
        table_combination_id: this.table_combination_id,
        duration: this.default_reservation_length,
        source_id: 2,
      };

      this.$http
        .post(
          this.$hostname + "/make-reservation",
          data,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          this.fetchTableReservations();
          this.fetchReservations();
          this.closeModal("walkin_reservation_modal");
        })
        .catch((err) => {
          console.log(err.response);
          this.showErrors(err);
        });
    },
  },
};
</script>
<style scoped>
.modal {
  height: 350px;
  gap: 12px;
  justify-content: flex-start;
  padding: 0;
}
.question {
  background-color: #ffffff;
  height: 100%;
  border-radius: 16px 16px 0 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

h3 {
  font-size: 18px;
  text-align: center;
  color: #737373;
}
.input {
  display: flex;
  flex-wrap: wrap;
}
.radio-input {
  display: none;
}

.radio-label {
  display: inline-block;
  margin: 5px;
  cursor: pointer;
}

.radio-custom {
  display: inline-block;
  padding: 10px 15px;
  border: 1px solid #f5f6f7;
  background-color: #f5f6f7;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.radio-input:checked + .radio-custom {
  color: #5a187a;
  border-color: #5a187a;
}
textarea {
  background-color: #f5f6f7;
  border-radius: 8px;
  padding: 12px;
  outline: none;
  resize: none;
  height: 72px;
  width: 100%;
}

textarea::placeholder {
  color: #b3b3b3;
}
.fill {
  border: 1px solid #5a187a;
  background-color: #5a187a;
}

.invalid {
  border-color: #b3b3b3;
  color: #b3b3b3;
  background-color: #f5f6f7;
}
.reject {
  background-color: #6ede75;
  border-color: #6ede75;
}
.arrived {
  background-color: #de6e6e;
  border-color: #de6e6e;
}
.row {
  display: flex;
  justify-content: space-between;
}
.reject,
.arrived {
  width: 49%;
}

select {
  width: 100%;
}
</style>
