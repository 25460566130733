<template>
  <div
    @click="openReservationModal(currentReservation)"
    class="table-reservation"
    :class="{
      table_reservation_2: table_reservation.capacity == 2,
      table_reservation_6: table_reservation.capacity == 3,
      table_reservation_4: table_reservation.capacity == 4,
      table_reservation_5: table_reservation.capacity == 5,
      table_reservation_6: table_reservation.capacity == 6,
      table_reservation_7: table_reservation.capacity == 7,
      table_reservation_8: table_reservation.capacity == 8,
    }"
    :style="[
      reservationStarted == 2
        ? { backgroundColor: '#db411a' }
        : reservationStarted == 1
        ? { backgroundColor: '#f0ec0c' }
        : reservationStarted == 3
        ? { backgroundColor: '#f29a57' }
        : { backgroundColor: '#6ede75' },
    ]"
  >
    <div class="table">
      {{ table_reservation.name }}
    </div>
    <div class="status">
      {{
        reservationStarted == 0
          ? "Vapaa"
          : reservationStarted == 1
          ? "Varaus"
          : reservationStarted == 3
          ? "Myöhässä"
          : "Varattu"
      }}
    </div>
    <div class="people">
      {{
        reservationStarted == 1 || reservationStarted == 3
          ? formatTime(currentReservation.reserved_at)
          : "(" + table_reservation.capacity + ")"
      }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["table_reservation", "default_reservation_length"],
  mounted() {
    this.isStarted();
  },
  data: () => ({
    reservationStarted: 0, // green = 0, yellow = 1, red = 2, orange = 3
    currentReservation: {},
  }),
  methods: {
    openReservationModal(currentReservation) {
      this.reservation_processing.data = currentReservation;
      this.reservation_processing.table_id = this.table_reservation.id;

      this.reservation_processing.current_table_combination_id = currentReservation.table_combination_id;

      this.$router.replace(`/reservation?modal=reservation`).catch(() => {});

      this.showModal("reservation_modal");
    },
    timeOver(val, reservation) {
      this.reservationStarted = val;
      this.currentReservation = reservation;
    },
    isStarted() {
      const timer = setInterval(() => {
        const now = new Date(); 

        this.table_reservation.reservations.forEach((reservation) => {
          const start_reservation = new Date(
            Date.parse(reservation.reserved_at)
          );
          const end_reservation = new Date(
            Date.parse(reservation.reserved_end)
          );

          const distance = start_reservation.getTime() - now.getTime();
          const reservation_over = end_reservation.getTime() - now.getTime();

          //   this.timeOver(0, {});
          if (reservation_over < 0 && this.currentReservation) {
            this.timeOver(0, {});
            return true;
          }

          if (distance < 0) {
            if (reservation.status == "arrived") {
              this.timeOver(2, reservation);
              return true;
            }
            this.timeOver(3, reservation);

            return true;
          }

          if (distance < this.default_reservation_length) {
            this.timeOver(1, reservation);
            // clearInterval(timer);
            return true;
          }
          if (!this.currentReservation) {
            this.timeOver(0, {});
          }
        });
      }, 1000);
    },
  },
};
</script>

<style scoped>
.table-reservation {
  padding: 4px;
  width: 90px;
  display: flex;
  justify-content: center;
  color: #3b3b3b;
  font-weight: 500;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  height: 90px;
  background-color: #6ede75;
  border-radius: 24px;
  cursor: pointer;
  transition: 0.2s;
  background-position: center;
  background-size: cover;
}
.table_reservation_2 {
  background-image: url(../../assets/restaurant/table-2.png);
}
.table_reservation_3 {
  background-image: url(../../assets/restaurant/table-3.png);
}
.table_reservation_4 {
  background-image: url(../../assets/restaurant/table-4.png);
}
.table_reservation_5 {
  background-image: url(../../assets/restaurant/table-5.png);
}
.table_reservation_6 {
  background-image: url(../../assets/restaurant/table-6.png);
}
.table_reservation_7 {
  background-image: url(../../assets/restaurant/table-7.png);
}
.table_reservation_8 {
  background-image: url(../../assets/restaurant/table-8.png);
}
.table-reservation:hover {
  transform: scale(1.03);
  filter: saturate(1.2);
}
.table {
  font-size: 20px;
  font-weight: 600;
}
</style>
