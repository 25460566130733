<template>
  <div id="reservation_modal" class="modal">
    <div class="question">
      <div class="row">
        <span></span>
        <button
          v-if="reservation_processing.data.id"
          @click="arrived(reservation_processing.data)"
          class="fill arrived"
        >
          {{ "Saapunut" }}
        </button>
        <button
          v-if="reservation_processing.data.id"
          @click="reject(reservation_processing.data)"
          class="fill reject"
        >
          {{ "Vapauta pöytä" }}
        </button>
        <button
          @click="closeModal('reservation_modal')"
          class="icon close"
        ></button>
      </div>
      <hr v-if="reservation_processing.data.id" />

      <div class="input">
        <label class="deactive" for="people">{{ $t("reserve.people") }}</label>
        <select
          class="active"
          type="number"
          id="people"
          v-model="reservation_processing.data.people"
        >
          <option v-for="index in 15" :key="index">
            {{ index }}
          </option>
        </select>
      </div>
      <div class="input">
        <label class="deactive" for="date">{{ "Päivä" }}</label>
        <input
          class="active"
          type="date"
          id="date"
          @change="fetchPeriods()"
          v-model="reservation_date"
        />
      </div>

      <div v-show="show_time_options" class="options">
        <!-- <div v-if="reservation_time" class="option-selected">
          <label for="option">{{ formatTimestamp(reservation_time) }}</label>
        </div> -->
        <div
          @click="fetchTableEnities(period)"
          class="reservation-input"
          v-for="(period, index) in periods"
          :key="index"
        >
          <div
            v-if="validHours(index)"
            :class="[
              reservation_time == index &&
              (table_id != 0 || table_combination_id != 0)
                ? 'option-selected'
                : 'option',
            ]"
          >
            <input
              name="reservation"
              v-model="reservation_time"
              :value="index"
              type="radio"
              hidden
              :id="'option' + index"
            />
            <label :for="'option' + index">{{ formatTimestamp(index) }}</label>
          </div>
        </div>
        <p class="no-tables-message" v-if="tables_available === 0">
          Ei vapaita pöytiä tälle päivälle.
        </p>
      </div>
      <hr />

      <div v-if="reservation_processing.data.table" class="current-table">
        <p v-if="reservation_processing.data.table.name">
          Nykyinen pöytä:
          <span>{{ reservation_processing.data.table.name }}</span>
        </p>
        <p v-else>
          Nykyinen pöytä: <span>{{ reservation_processing.data.table }}</span>
        </p>
      </div>
      <div
        v-else-if="reservation_processing.data.table_combination"
        class="current-table"
      >
        <p v-if="reservation_processing.data.table_combination.name">
          Nykyinen pöytä:
          <span>{{ reservation_processing.data.table_combination.name }}</span>
        </p>
        <p v-else>
          Nykyinen pöytä:
          <span>{{ reservation_processing.data.table_combination }}</span>
        </p>
      </div>

      <div class="input-group">
        <label v-for="table in tables" :key="table.id" class="radio-label">
          <input
            type="radio"
            name="table"
            :value="table.id"
            v-model="reservation_processing.table_id"
            class="radio-input"
            @change="clearTableCombination"
          />
          <span class="radio-custom">{{ table.name }}</span>
        </label>
      </div>

      <div class="input-group">
        <label
          v-for="table_combination in table_combinations"
          :key="table_combination.id"
          class="radio-label"
        >
          <input
            type="radio"
            name="table_combination"
            :value="table_combination.id"
            v-model="reservation_processing.current_table_combination_id"
            class="radio-input"
            @change="clearTable"
          />
          <span class="radio-custom">{{ table_combination.name }}</span>
        </label>
      </div>

      <div class="input">
        <label class="deactive" for="fullname">{{
          $t("reserve.full_name")
        }}</label>
        <input class="active" type="text" id="fullname" v-model="full_name" />
      </div>

      <button @click="save()" :class="[valid ? '' : 'invalid', 'fill']">
        {{ $t("information.save") }}
        <!-- {{ valid }} -->
      </button>
      <button
        v-if="!reservation_processing.data.id"
        @click="createWalkinReservation()"
        :class="[valid ? '' : 'invalid', 'outline']"
      >
        {{ $t("reserve.walkin") }}
        <!-- {{ valid }} -->
      </button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selected_table: 0,
      reservation_date: "",
      reservation_time: "",
      full_name: "",
      table_id: null,
      table_combination_id: null,
      periods: [],
      restaurant_qr: "",
      tables_available: 1,
      selected_time: {},
      selected_time_show: "",
      show_time_options: true,
      show_selected_time: false,
      not_found_times: false,
      tables: [],
      table_combinations: [],
    };
  },
  props: {
    default_reservation_length: 0,
  },
  mounted() {
    this.fetchQR();
  },
  watch: {
    $route(to, from) {

      if (to.query.modal == "reservation") {
        this.displayDate();
        this.displayTime();
        this.displayName();
      }

      if (from.query.modal == "reservation") {
        this.clearDateTime();
        this.clearName();
      }
    },
  },
  computed: {
    valid() {
      return true;
    },
  },
  methods: {
    displayDate() {
      if (this.reservation_processing.data.id) {
        const reservation_datetime = new Date(
          Date.parse(this.reservation_processing.data.reserved_at)
        );
        this.reservation_date = reservation_datetime
          .toISOString()
          .split("T")[0];

        this.fetchPeriods();
      }
    },
    displayTime() {
      if (this.reservation_processing.data.id) {
        this.reservation_time = this.reservation_processing.data.reserved_at;

        if (this.reservation_time && this.periods) {
          this.selectSlotAndFetchTables();
        }
      }
    },
    displayName() {
      if (this.reservation_processing.data.id) {
        this.full_name = this.reservation_processing.data.full_name;
      }
    },
    selectSlotAndFetchTables() {
      if (!this.periods || !this.reservation_time) return;

      // Find the matching slot
      const selectedSlot = Object.entries(this.periods).find(([key, value]) => {
        return key === this.reservation_time; // Ensure time format matches
      });

      if (selectedSlot) {
        this.fetchTableEnities(selectedSlot[1]); // Pass the slot object
      }
    },
    fetchTableEnities(slot) {
      let data = {
        tables: slot.tables,
        table_combinations: slot.table_combinations,
      };
      this.$http
        .post(this.$hostname + "/table-ids", data, this.restaurant_token.config)
        .then((resp) => {
          this.tables = resp.data.tables;
          this.table_combinations = resp.data.table_combinations;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    fetchQR() {
      this.$http
        .get(this.$hostname + "/profile", this.restaurant_token.config)
        .then((resp) => {
          this.restaurant_qr = resp.data.profile.restaurant[0].menu.qr_url;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    fetchPeriods() {
      if (this.reservation_processing.current_table_combination_id) {
        this.clearTable();
      } else {
        this.clearTableCombination();
      }

      this.tables = [];
      this.table_combinations = [];
      this.show_selected_time = false;
      this.selected_time = {};
      (this.selected_time_show = ""),
        this.$http
          .get(
            this.$hostname +
              "/slots?qr=" +
              this.restaurant_qr +
              "&date=" +
              this.reservation_date +
              "&people=" +
              this.reservation_processing.data.people
          )
          .then((resp) => {
            this.periods = resp.data;
            this.checkTablesAvailability();
            this.show_time_options = true;

            // Call selectSlotAndFetchTables() when data is ready
            this.$nextTick(() => {
              this.selectSlotAndFetchTables();
            });
          })
          .catch((err) => {
            console.log(err);
          });
    },
    checkTablesAvailability() {
      const allEmpty = Object.values(this.periods).every(
        (period) =>
          period.tables.length === 0 && period.table_combinations.length === 0
      );
      this.tables_available = allEmpty ? 0 : 1;

      this.periods = Object.fromEntries(
        Object.entries(this.periods).filter(([key, value]) => {
          return this.validHours(key) /* &&
            (value.tables.length > 0 || value.table_combinations.length > 0) */;
        })
      );
    },
    formatTimestamp(datetime) {
      const formatedTime = new Date(datetime);
      return (
        formatedTime.getHours() +
        ":" +
        (formatedTime.getMinutes() == 0 ? "00" : formatedTime.getMinutes())
      );
    },
    validHours(hour) {
      let now = new Date();
      let time = new Date(hour);
      return time - now >= 0;
    },
    clearTableCombination() {
      this.reservation_processing.current_table_combination_id = null;
      if (this.table_id) {
        this.current_table_combination_id = null;
      }
    },
    clearTable() {
      this.reservation_processing.table_id = null;
      if (this.table_combination_id) {
        this.table_id = null;
      }
    },
    clearTime() {
      this.reservation_time = "";
      this.reservation_date = "";
      this.selected_time = {};
      this.periods = [];
      // this.show_selected_time = false;
      this.table_id = 0;
      this.tables = [];
      this.table_combinations = [];
    },
    clearDateTime() {
      this.reservation_date = "";
      this.clearTime();
    },
    clearName() {
      this.full_name = "";
    },
    arrived() {
      this.arrivedReservation(this.reservation_processing.data);
      this.closeModal("reservation_modal");
    },
    reject() {
      if (confirm("Oletko varma että haluat poistaa varauksen?")) {
        this.rejectReservation(this.reservation_processing.data, "cancel");
        this.closeModal("reservation_modal");
      }
    },
    save() {
      if (this.reservation_processing.data.id) {
        this.updateReservation();
      } else {
        this.createReservation();
      }
    },
    createWalkinReservation() {
      const now = new Date();
      const localTime = new Intl.DateTimeFormat("en-US", {
        timeZone: "Europe/Helsinki",
        timeStyle: "medium",
        dateStyle: "long",
      }).format(now);

      let data = {
        datetime: localTime,
        status: "arrived",
        full_name: "Walk-in",
        email: "service@nordantia.com",
        message: "Walk-in",
        phone_number: "",
        people: 4,
        table_id: this.reservation_processing.table_id,
        table_combination_id:
          this.reservation_processing.current_table_combination_id,
        duration: this.default_reservation_length,
        source_id: 2,
      };

      this.$http
        .post(
          this.$hostname + "/make-reservation",
          data,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          this.fetchTableReservations();
          this.clearDateTime();
          this.fetchReservations();
          this.closeModal("reservation_modal");
        })
        .catch((err) => {
          console.log(err.response);
          this.showErrors(err);
        });
    },
    createReservation() {
      let data = {
        datetime: this.reservation_time,
        full_name: this.full_name,
        email: "service@nordantia.com",
        message: "Puhelu",
        phone_number: "",
        people: this.reservation_processing.data.people,
        table_id: this.reservation_processing.table_id,
        table_combination_id:
          this.reservation_processing.current_table_combination_id,
        duration: this.default_reservation_length,
        source_id: 2,
      };

      this.$http
        .post(
          this.$hostname + "/make-reservation",
          data,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          this.fetchTableReservations();
          this.clearDateTime();
          this.closeModal("reservation_modal");
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    updateReservation() {
      let data = {
        full_name: this.full_name,
        email: this.reservation_processing.data.email,
        message: this.reservation_processing.data.message,
        status: this.reservation_processing.data.status,
        phone_number: this.reservation_processing.data.phone_number,
        people: this.reservation_processing.data.people,
        datetime: this.reservation_time,
        duration: this.reservation_processing.data.duration,
        table_id: this.reservation_processing.table_id,
        table_combination_id:
          this.reservation_processing.current_table_combination_id,
        source_id: this.reservation_processing.data.source_id,
      };
      this.global_loading.show = true;
      this.$http
        .patch(
          this.$hostname +
            "/reservations/" +
            this.reservation_processing.data.id,
          data,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          this.closeModal("reservation_modal");
          window.location.reload(true);
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
  },
};
</script>
<style scoped>
.modal {
  gap: 12px;
  justify-content: flex-start;
  padding: 0;
  overflow: scroll;
  max-height: 100%;
}
.question {
  background-color: #ffffff;
  height: 100%;
  border-radius: 16px 16px 0 0;
  padding: 16px 16px 80px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

h3 {
  font-size: 18px;
  text-align: center;
  color: #737373;
}

textarea {
  background-color: #f5f6f7;
  border-radius: 8px;
  padding: 12px;
  outline: none;
  resize: none;
  height: 72px;
  width: 100%;
}

textarea::placeholder {
  color: #b3b3b3;
}
.fill {
  border: 1px solid #5a187a;
  background-color: #5a187a;
}

.invalid {
  border-color: #b3b3b3;
  color: #b3b3b3;
  background-color: #f5f6f7;
}
.reject {
  background-color: transparent;
  border-color: #e22e44;
  color: #e22e44;
}
.arrived {
  background-color: #2a88d2;
  border-color: #2a88d2;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.reject,
.arrived {
  width: 40%;
}

select {
  width: 100%;
}

.outline {
  font-weight: normal;
  border: 1px solid #5a187a;
  font-size: 14px;
  color: #5a187a;
  background-color: transparent;
}
.options {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  max-height: 120px;
  overflow: scroll;
}
.options label {
  background-color: #f8f9fa;
  border-radius: 8px;
  width: 60px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4d4d4d;
  cursor: pointer;
}

input[type="radio"]:checked + label {
  border: 1px solid #a328fb;
}

input[type="radio"] {
  appearance: none;
  display: none;
}

.option-off {
  text-decoration: line-through;
}

.radio-input {
  display: none;
}

.radio-label {
  display: inline-block;
  margin: 5px;
  cursor: pointer;
}

.radio-custom {
  display: inline-block;
  padding: 10px 15px;
  border: 1px solid #f5f6f7;
  background-color: #f5f6f7;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.radio-input:checked + .radio-custom {
  color: #5a187a;
  border-color: #5a187a;
}

.current-table {
  padding: 0 10px;
}
.current-table span {
  font-weight: 600;
}

.close {
  background-image: url("../../assets/common/close.svg");
  height: 28px;
  width: 28px;
}
</style>
