<template>
  <div>
    <div ref="timeline"></div>
  </div>
</template>

<script>
  import { Timeline } from "vis-timeline/standalone";
  import "vis-timeline/styles/vis-timeline-graph2d.min.css";
  import dayjs from "dayjs";

  export default {
    props: {
      reservations: {
        type: Array,
        required: true
      },
      tables: {
        type: Array,
        required: true
      },
    },
    data() {
      return {
        timeline: null,
        currentDate: dayjs().format("YYYY-MM-DD"),
        groups: [],
      };
    },
    watch: {
      reservations: {
        handler() {
          this.parsedReservations = this.parseReservations(); // Update when reservations change
          this.groups = this.parseTables(); // Update when tables change
          this.initTimeline();
        },
        deep: true,
        immediate: true, // Run once on mount
      },
    },
    mounted() {
      this.parsedReservations = this.parseReservations();
      this.groups = this.parseTables();
      this.$nextTick(() => {  // Ensure DOM is fully rendered before using ref
        if (this.$refs.timeline) {
          this.initTimeline();
        }
      });
    },
    methods: {
      initTimeline() {
        if (!this.$refs.timeline) return; // Prevent errors if ref is missing

        if (this.timeline) this.timeline.destroy(); // Destroy previous instance

        this.timeline = new Timeline(this.$refs.timeline, this.parsedReservations, this.groups, {
          start: new Date(new Date().getTime() - 1 * 60 * 60 * 1000),
          end: new Date(new Date().getTime() + 5 * 60 * 60 * 1000),
          hiddenDates: [
            { start: "2025-02-15T00:00:00", end: "2025-02-15T09:00:00", repeat: "daily" }
          ],
          orientation: { axis: "top" },
          zoomable: true,
          zoomMin: 1000 * 60 * 60 * 3,
          zoomMax: 1000 * 60 * 60 * 24,
          stack: false,
          groupHeightMode: 'fixed',
          //maxHeight: 600,
        });

        // Click event
        this.timeline.on("select", (event) => {
          const item = this.parsedReservations.find((r) => r.id === event.items[0]);
          if (item) 
          setTimeout(() => {
            this.openReservationModal(item.data);
          }, 100);
        });
      },
      openReservationModal(currentReservation) {
        this.reservation_processing.data = currentReservation;

        this.reservation_processing.table_id = currentReservation.table_id;

        this.reservation_processing.current_table_combination_id = currentReservation.table_combination_id;

        this.$router.replace(`/reservation?modal=reservation`).catch(() => {});

        this.showModal("reservation_modal");
      },
      parseReservations() {
        return this.reservations.flatMap((res) => {
          const tables = res.table_combination
            ? res.table_combination.split("-").map(Number)
            : [Number(res.table)];

          return tables.map((table) => ({
            id: `${res.id}-${table}`,
            group: table,
            content: `${res.full_name} | ${res.people}`,
            start: dayjs(res.reserved_at).toISOString(),
            end: dayjs(res.reserved_end).toISOString(),
            data: res,
            className: res.table_combination ? "vis-item-combination" : "",
          }));
        });
      },
      parseTables() {
        return this.tables.map((table) => ({
          id: table.name,
          content: `${table.name} (${table.capacity})`,
        }));
      }
    },
  }
</script>

<style>
  .vis-item {
    background-color: #D7EFFB;
    color: black;
    border-color: #106CC4;
    padding: 0px;
    height: 34px;
  }
  .vis-item-combination {
    background-color: #d7fbda;
    border-color: #10c44c;
  }
  .vis-item .vis-item-overflow {
    display: flex;
    align-items: center;
}
  .vis-item.vis-range {
    border-radius: 10px;
  }
  .vis-item.vis-selected {
    background-color: #dadadaee;
    border-color: #6d6d6d;
    color: #000;
  }
</style>
