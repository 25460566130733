<template>
  <div class="info">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/profile'"></back-button>
        <div class="title">{{ $t("home.services") }}</div>
        <call-waiter></call-waiter>
      </div>
    </div>
    <div class="content">
      <!-- night shift charge -->
      <h2>
        {{ $t("info.night_shift") }}
      </h2>
      <div class="night_shift_charge">
        <div class="note">
          {{ $t("info.night_shift_note") }}
        </div>

        <div class="hour">
          <v-text-field
            v-model="start_night_shift"
            filled
            type="time"
            :label="$t('info.from')"
          ></v-text-field>
          -

          <v-text-field
            v-model="end_night_shift"
            filled
            :label="$t('info.to')"
            type="time"
          ></v-text-field>
          <v-text-field
            v-model="night_shift_charge"
            filled
            suffix="€"
            :label="$t('order.night_shift')"
          ></v-text-field>
        </div>
      </div>

      <!-- services of restaurants -->
      <h4 class="serves">{{ $t("information.max_options") }}:</h4>
      <div class="options">
        <div @click="switchOption(wifi, 1)" class="option">
          <div :class="[wifi == 1 ? 'wifi_on' : 'wifi', 'icon']"></div>
          <div class="caption">Free WI-FI</div>
        </div>
        <div @click="switchOption(bar, 2)" class="option">
          <div :class="[bar == 1 ? 'bar_on' : 'bar', 'icon']"></div>
          <div class="caption">Bar</div>
        </div>
        <div @click="switchOption(smoking, 3)" class="option">
          <div :class="[smoking == 1 ? 'smoking_on' : 'smoking', 'icon']"></div>
          <div class="caption">No Smoking</div>
        </div>
        <div @click="switchOption(parking, 4)" class="option">
          <div :class="[parking == 1 ? 'parking_on' : 'parking', 'icon']"></div>
          <div class="caption">Car Park</div>
        </div>
        <div @click="switchOption(wheelchair, 5)" class="option">
          <div
            :class="[wheelchair == 1 ? 'wheelchair_on' : 'wheelchair', 'icon']"
          ></div>
          <div class="caption">Wheelchair Accessible</div>
        </div>
        <div @click="switchOption(outdoor, 6)" class="option">
          <div :class="[outdoor == 1 ? 'outdoor_on' : 'outdoor', 'icon']"></div>
          <div class="caption">Outoor Dining</div>
        </div>
      </div>
      <button @click="done()" class="fill">{{ $t("information.save") }}</button>
    </div>

    <div id="blur_background" class="blur-bg">
      <calls-modal></calls-modal>
    </div>
  </div>
</template>
<script>
import BackButton from "../../../components/common/BackButton.vue";
import CallsModal from "../../../components/restaurant/CallsModal.vue";
import CallWaiter from "../../../components/restaurant/CallWaiter.vue";

export default {
  components: {
    BackButton,
    CallWaiter,
    CallsModal,
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.global_loading.show = true;
      this.$http
        .get(this.$hostname + "/info", this.restaurant_token.config)
        .then((resp) => {
          this.successRequest();

          this.wifi = resp.data.info[0].wifi;
          this.bar = resp.data.info[0].bar;
          this.smoking = resp.data.info[0].smoking;
          this.parking = resp.data.info[0].parking;
          this.wheelchair = resp.data.info[0].wheelchair;
          this.outdoor = resp.data.info[0].outdoor;

          this.start_night_shift = resp.data.info[0].start_night_shift;
          this.end_night_shift = resp.data.info[0].end_night_shift;
          this.night_shift_charge = resp.data.info[0].night_shift_charge;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    save() {
      let fd = new FormData();

      fd.append("wifi", this.wifi);
      fd.append("bar", this.bar);
      fd.append("smoking", this.smoking);
      fd.append("parking", this.parking);
      fd.append("wheelchair", this.wheelchair);
      fd.append("outdoor", this.outdoor);

      fd.append("start_night_shift", this.start_night_shift);
      fd.append("end_night_shift", this.end_night_shift);
      fd.append("night_shift_charge", this.night_shift_charge);

      this.global_loading.show = true;
      this.$http
        .post(
          this.$hostname + "/services-info",
          fd,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    done() {
      this.save();
      this.backToHome();
    },
    backToHome() {
      this.$router.push("/info");
    },
    switchOption(val, index) {
      if (this.optionsChecker || val == 1) {
        switch (index) {
          case 1:
            if (val == 1) {
              this.wifi = 0;
            } else {
              this.wifi = 1;
            }
            break;
          case 2:
            if (val == 1) {
              this.bar = 0;
            } else {
              this.bar = 1;
            }
            break;
          case 3:
            if (val == 1) {
              this.smoking = 0;
            } else {
              this.smoking = 1;
            }
            break;
          case 4:
            if (val == 1) {
              this.parking = 0;
            } else {
              this.parking = 1;
            }
            break;
          case 5:
            if (val == 1) {
              this.wheelchair = 0;
            } else {
              this.wheelchair = 1;
            }
            break;
          case 6:
            if (val == 1) {
              this.outdoor = 0;
            } else {
              this.outdoor = 1;
            }
            break;

          default:
            break;
        }
      }
    },
  },
  computed: {
    optionsChecker() {
      this.options = [
        this.wifi,
        this.bar,
        this.smoking,
        this.parking,
        this.wheelchair,
        this.outdoor,
      ];

      if (this.options.filter((x) => x === 1).length < 3) {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      start_night_shift: "",
      end_night_shift: "",
      night_shift_charge: 0,
      wifi: 0,
      bar: 0,
      smoking: 0,
      parking: 0,
      wheelchair: 0,
      outdoor: 0,
      options: [],
    };
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}
h2 {
  font-weight: 500;
  font-size: 20px;
  color: #737373;
}
.content {
  height: 100vh;
  overflow-y: scroll;
  padding: 78px 16px 96px;
}
.form {
  padding: 16px 0 0;
}
.serves {
  border-top: 1px solid #f0edf2;
  margin-top: 16px;
}
.options {
  padding: 16px 0;
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 16px;
}
.option {
  width: 30%;
  font-weight: 300;
  font-size: 0.8em;
  color: #737373;
  height: 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

h4 {
  padding: 16px 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
}
.wifi_on {
  background-image: url("../../../assets/restaurant/home/wifi_on.svg");
}
.wifi {
  background-image: url("../../../assets/restaurant/home/wifi.svg");
}
.bar_on {
  background-image: url("../../../assets/restaurant/home/bar_on.svg");
}
.bar {
  background-image: url("../../../assets/restaurant/home/bar.svg");
}
.smoking_on {
  background-image: url("../../../assets/restaurant/home/smoking_on.svg");
}
.smoking {
  background-image: url("../../../assets/restaurant/home/smoking.svg");
}
.parking_on {
  background-image: url("../../../assets/restaurant/home/parking_on.svg");
}
.parking {
  background-image: url("../../../assets/restaurant/home/parking.svg");
}
.wheelchair_on {
  background-image: url("../../../assets/restaurant/home/wheelchair_on.svg");
}
.wheelchair {
  background-image: url("../../../assets/restaurant/home/wheelchair.svg");
}
.outdoor_on {
  background-image: url("../../../assets/restaurant/home/outdoor_on.svg");
}
.outdoor {
  background-image: url("../../../assets/restaurant/home/outdoor.svg");
}

button.fill {
  min-height: 48px;
  margin-top: 12px;
}

.night_shift_charge {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0 0;
}

.night_shift_charge .note {
  color: #b3b3b3;
}

.hour {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
}

.hour div {
  width: 32%;
}
</style>