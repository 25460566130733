<template>
  <div id="call_reservation_modal" class="modal">
    <div class="question">
      <div class="row">
        <button @click="closeModal('call_reservation_modal')" class="icon close"></button>
      </div>
      <div class="input">
        <label class="deactive" for="people">{{ $t("reserve.people") }}</label>
        <select
          class="active"
          type="number"
          id="people"
          v-model="people"
          @change="clearDateTime()"
        >
          <option v-for="index in 15" :key="index">
            {{ index }}
          </option>
        </select>
      </div>

      <div class="input">
        <label class="deactive" for="date">{{ "Päivä" }}</label>
        <input
          class="active"
          type="date"
          id="date"
          @change="fetchPeriods()"
          v-model="reservation_date"
        />
      </div>

      <div v-show="show_time_options" class="options">
        <div
          @click="fetchTableEnities(period)"
          class="reservation-input"
          v-for="(period, index) in periods"
          :key="index"
        >
          <div
            v-if="validHours(index)"
            :class="[
              reservation_time == index &&
              (table_id != 0 || table_combination_id != 0)
                ? 'option-selected'
                : 'option',
              period.tables[0] || period.table_combinations[0]
                ? 'option'
                : 'option-off',
            ]"
          >
            <input
              name="reservation"
              v-model="reservation_time"
              :value="index"
              type="radio"
              hidden
              :id="'option' + index"
            />
            <label :for="'option' + index">{{ formatTimestamp(index) }}</label>
          </div>
        </div>
        <p class="no-tables-message" v-if="tables_available === 0">
          Ei vapaita pöytiä tälle päivälle.
        </p>
      </div>
      <hr>
      <div class="input-group">
        <p>Pöytä:</p>
        <label v-for="table in tables" :key="table.id" class="radio-label">
          <input
            type="radio"
            name="table"
            :value="table.id"
            v-model="table_id"
            class="radio-input"
            @change="clearTableCombination"
          />
          <span class="radio-custom">{{ table.name }}</span>
        </label>
      </div>

      <div class="input-group">
        <label
          v-for="table_combination in table_combinations"
          :key="table_combination.id"
          class="radio-label"
        >
          <input
            type="radio"
            name="table_combination"
            :value="table_combination.id"
            v-model="table_combination_id"
            class="radio-input"
            @change="clearTable"
          />
          <span class="radio-custom">{{ table_combination.name }}</span>
        </label>
      </div>

      <div class="input">
        <label class="deactive" for="fullname">{{
          $t("reserve.full_name")
        }}</label>
        <input class="active" type="text" id="fullname" v-model="full_name" />
      </div>

      <button
        @click="createReservation()"
        :class="[valid ? '' : 'invalid', 'fill']"
      >
        {{ $t("information.save") }}
        <!-- {{ valid }} -->
      </button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      reservation_date: "",
      reservation_time: "",
      tables: [],
      table_combinations: [],
      table_id: null,
      table_combination_id: null,
      full_name: "",
      people: 1,
      periods: [],
      restaurant_qr: "",
      tables_available: 1,
      selected_time: {},
      selected_time_show: "",
      show_time_options: true,
      show_selected_time: false,
      not_found_times: false,
    };
  },
  computed: {
    valid() {
      return true;
    },
  },
  mounted() {
    this.fetchQR();
  },
  methods: {
    fetchTableEnities(slot) {
      let data = {
        tables: slot.tables,
        table_combinations: slot.table_combinations,
      };
      this.$http
        .post(this.$hostname + "/table-ids", data, this.restaurant_token.config)
        .then((resp) => {
          this.tables = resp.data.tables;
          this.table_combinations = resp.data.table_combinations;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    fetchQR() {
      this.$http
        .get(this.$hostname + "/profile", this.restaurant_token.config)
        .then((resp) => {
          this.restaurant_qr = resp.data.profile.restaurant[0].menu.qr_url;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    fetchPeriods() {
      this.tables = [];
      this.table_combinations = [];
      this.show_selected_time = false;
      this.selected_time = {};
      (this.selected_time_show = ""),
        this.$http
          .get(
            this.$hostname +
              "/slots?qr=" +
              this.restaurant_qr +
              "&date=" +
              this.reservation_date +
              "&people=" +
              this.people
          )
          .then((resp) => {
            this.periods = resp.data;
            this.checkTablesAvailability();
            this.show_time_options = true;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    checkTablesAvailability() {
      const allEmpty = Object.values(this.periods).every(
        (period) =>
          period.tables.length === 0 && period.table_combinations.length === 0
      );
      this.tables_available = allEmpty ? 0 : 1;

      this.periods = Object.fromEntries(
        Object.entries(this.periods).filter(([key, value]) => {
          return (
            this.validHours(key) &&
            (value.tables.length > 0 || value.table_combinations.length > 0)
          );
        })
      );
    },
    formatTimestamp(datetime) {
      const formatedTime = new Date(datetime);
      return (
        formatedTime.getHours() +
        ":" +
        (formatedTime.getMinutes() == 0 ? "00" : formatedTime.getMinutes())
      );
    },
    validHours(hour) {
      let now = new Date();
      let time = new Date(hour);
      return time - now >= 0;
    },
    clearTableCombination() {
      if (this.table_id) {
        this.table_combination_id = null;
      }
    },
    clearTable() {
      if (this.table_combination_id) {
        this.table_id = null;
      }
    },
    clearTime() {
      this.reservation_time = "";
      this.reservation_date = "";
      this.selected_time = {};
      this.periods = [];
      this.show_selected_time = false;
      this.table_id = 0;
      this.tables = [];
      this.table_combinations = [];
    },
    clearDateTime() {
      this.reservation_date = "";
      this.clearTime();
    },
    createReservation() {
      let data = {
        datetime: this.reservation_time,
        full_name: this.full_name,
        email: "service@nordantia.com",
        message: "",
        phone_number: "",
        people: this.people,
        table_id: this.table_id,
        table_combination_id: this.table_combination_id,
        duration: this.default_reservation_length,
        source_id: 2,
      };

      this.$http
        .post(
          this.$hostname + "/make-reservation",
          data,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          this.fetchTableReservations();
          this.fetchReservations();
          this.clearDateTime();
          this.closeModal("call_reservation_modal");
        })
        .catch((err) => {
          console.log(err.response);
          this.showErrors(err);
        });
    },
  },
};
</script>
<style scoped>
.modal {
  gap: 12px;
  justify-content: flex-start;
  padding: 0;
  overflow: scroll;
  max-height: 100%;
}
.question {
  background-color: #ffffff;
  height: 100%;
  border-radius: 16px 16px 0 0;
  padding: 16px 16px 80px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

h3 {
  font-size: 18px;
  text-align: center;
  color: #737373;
}

.radio-input {
  display: none;
}

.radio-label {
  display: inline-block;
  margin: 5px;
  cursor: pointer;
}

.radio-custom {
  display: inline-block;
  padding: 10px 15px;
  border: 1px solid #f5f6f7;
  background-color: #f5f6f7;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.radio-input:checked + .radio-custom {
  color: #5a187a;
  border-color: #5a187a;
}
textarea {
  background-color: #f5f6f7;
  border-radius: 8px;
  padding: 12px;
  outline: none;
  resize: none;
  height: 72px;
  width: 100%;
}

textarea::placeholder {
  color: #b3b3b3;
}
.fill {
  border: 1px solid #5a187a;
  background-color: #5a187a;
}

.invalid {
  border-color: #b3b3b3;
  color: #b3b3b3;
  background-color: #f5f6f7;
}
.reject {
  background-color: #6ede75;
  border-color: #6ede75;
}
.arrived {
  background-color: #de6e6e;
  border-color: #de6e6e;
}
.row {
  display: flex;
  justify-content: end;
}
.reject,
.arrived {
  width: 49%;
}

select {
  width: 100%;
}
.options {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  max-height: 160px;
  overflow: scroll;
}
.options label {
  background-color: #f8f9fa;
  border-radius: 8px;
  width: 76px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4d4d4d;
  cursor: pointer;
}

input[type="radio"]:checked + label {
  border: 1px solid #a328fb;
}

input[type="radio"] {
  appearance: none;
  display: none;
}

.option-off {
  text-decoration: line-through;
}

.close {
  background-image: url("../../assets/common/close.svg");
  height: 28px;
  width: 28px;
}
</style>
