<template>
  <div class="history">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/'"></back-button>
        <div class="title">{{ $t("home.lunch") }}</div>
        <call-waiter></call-waiter>
      </div>
    </div>
    <div class="content">
      <router-link class="btn settings" to="/lunch-settings">
        <div class="left">
          <div class="icon"></div>
          <h4 class="caption">{{ $t("home.settings") }}</h4>
        </div>
        <div class="icon arrow"></div>
      </router-link>

      <fixed-lunch-days v-if="lunch_type == 0"></fixed-lunch-days>
      <lunch-weeks v-else></lunch-weeks>
    </div>
    <div id="blur_background" class="blur-bg">
      <calls-modal></calls-modal>
      <fixed-lunch-day-modal></fixed-lunch-day-modal>
    </div>
  </div>
</template>
<script>
import BackButton from "../../components/common/BackButton.vue";
import CallWaiter from "../../components/restaurant/CallWaiter.vue";
import CallsModal from "../../components/restaurant/CallsModal.vue";
import FixedLunchDays from "../../components/restaurant/lunch/FixedLunchDays.vue";
import LunchWeeks from "../../components/restaurant/lunch/LunchWeeks.vue";
import FixedLunchDayModal from "../../components/restaurant/lunch/FixedLunchDayModal.vue";

export default {
  components: {
    BackButton,
    CallWaiter,
    CallsModal,
    FixedLunchDays,
    FixedLunchDayModal,
    LunchWeeks,
  },
  mounted() {
    this.fetchLunchSettings();
  },
  methods: {
    fetchLunchSettings() {
      this.$http
        .get(this.$hostname + "/lunch", this.restaurant_token.config)
        .then((resp) => {
          this.lunch_type = resp.data.result.lunch_type;
          if (resp.data.result.lunch_type == 1) {
            this.fetchLunchWeeks();
          } else {
            this.fetchFixedLunchDays();
          }
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
  },
  data() {
    return {
      lunch_type: 0,
      fixed_lunch_days: [],
    };
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}
.content {
  overflow-y: scroll;
  padding: 84px 16px 72px;
  gap: 8px;
}

.settings .left .icon {
  background-image: url("../../assets/restaurant/navbar/settings_on.svg");
}
.settings {
  width: 100%;
  height: 48px;
  gap: 9px;
}
</style>
