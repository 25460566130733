<template>
  <div id="print_reservation_modal" class="modal">
    <div class="question">

      <h3>Varausraportti</h3>
      <div class="checkboxes">
        <label v-for="(label, key) in availableFields" :key="key">
          <input type="checkbox" v-model="selectedFields" :value="key" />
          {{ label }}
        </label>
      </div>
      <div class="buttons">
        <button @click="printReport" class="fill print">Tulosta</button>
        <button @click="closeModal('print_reservation_modal')" class="fill cancel">Sulje</button>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  props: {
    reservations: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      selectedFields: ["reserved_at", "people", "table", "message", "restaurant_message"],
      availableFields: {
        reserved_at: "Aika",
        people: "Ryhmän koko",
        table: "Pöytä",
        full_name: "Nimi",
        email: "Sähköposti",
        phone_number: "Puhelinnumero",
        message: "Asiakkaan viesti",
        restaurant_message: "Ravintolan muistiinpano",
        
      }
    };
  },
  methods: {
    printReport() {
      let printContent = "";
      this.reservations.forEach(reservation => {
        let tableInfo = reservation.table ? reservation.table : reservation.table_combination;
        let firstLine = this.selectedFields
          .filter(field => ["email", "full_name", "people", "phone_number", "reserved_at", "table"].includes(field))
          .map(field => {
            if (field === "reserved_at") return this.formatDatetime(reservation.reserved_at);
            if (field === "people") return `Ryhmä: ${reservation[field]}`;
            if (field === "table") return `Pöytä: ${tableInfo}`;
            return reservation[field];
          })
          .join(" | ");

        let lines = [firstLine];
        if (this.selectedFields.includes("message") && reservation.message) {
          lines.push(...reservation.message.match(/.{1,70}/g));
        }
        if (this.selectedFields.includes("restaurant_message") && reservation.restaurant_message) {
          lines.push(...reservation.restaurant_message.match(/.{1,70}/g));
        }
        
        printContent += lines.join("\n") + "\n\n";
      });
      
      let newWindow = window.open("", "_blank");
      newWindow.document.write("<pre>" + printContent + "</pre>");
      newWindow.document.close();
      newWindow.print();
    }
  }
};
</script>
<style scoped>
.modal {
  max-width: 400px;
  gap: 12px;
  justify-content: flex-start;
  padding: 0;
}
.question {
  background-color: #ffffff;
  height: 100%;
  border-radius: 16px 16px 0 0;
  padding: 16px 16px 80px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

h3 {
  font-size: 18px;
  text-align: center;
  color: #737373;
}
.checkboxes {
  display: flex;
  flex-direction: column;
}
label {
  display: flex;
  align-items: center;
  gap: 8px;
}
.buttons {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
}
.print {
  background-color: #2A88D2;
  border-color: #2A88D2;
}
.cancel {
  background-color: transparent;
  border-color: #E22E44;
  color: #E22E44;
}
.print,
.cancel {
  width: 40%;
}

</style>
