<template>
  <div class="info">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/'"></back-button>
        <div class="title">{{ $t("order.delivery") }}</div>
        <call-waiter></call-waiter>
      </div>
    </div>
    <div class="content">
      <v-text-field
        v-model="max_preparation_time"
        filled
        :suffix="$t('order.minutes')"
        :label="$t('info.max_delivery_time')"
      ></v-text-field>
      <div class="note">*{{ $t("info.max_delivery_time_desc") }}</div>

      <v-text-field
        v-model="max_distance_order_delivery"
        filled
        suffix="km"
        :label="$t('info.max_distance')"
      ></v-text-field>
      <div class="note">*{{ $t("info.max_distance_desc") }}</div>

      <v-text-field
        v-model="min_amount_order_delivery"
        filled
        suffix="€"
        :label="$t('info.min_order')"
      ></v-text-field>
      <div class="note">*{{ $t("info.min_order_desc") }}</div>

      <div class="hours">
        <button @click="showModal('delivery_hours_modal')" class="light">
          {{ $t("info.delivery_hours") }}
        </button>
      </div>

      <h3>{{ $t("order.delivery_fee") }}</h3>

      <div class="free-delivery">
        <div class="label">
          <input
            type="checkbox"
            v-model="first_free_delivery_option"
            name="first-free-delivery"
            id="first-free-delivery"
          />
          <label for="first-free-delivery"
            >{{ $t("settings.free_delivery1") }}:</label
          >
        </div>

        <v-text-field
          :disabled="first_free_delivery_option == 0"
          v-model="first_max_distance_free_delivery"
          filled
          suffix="km"
        />
        <span>
          {{ $t("settings.free_delivery2") }}
        </span>
        <v-text-field
          :disabled="first_free_delivery_option == 0"
          v-model="first_min_amount_free_delivery"
          filled
          suffix="€"
        />
      </div>

      <div class="free-delivery">
        <div class="label">
          <input
            type="checkbox"
            v-model="exception_delivery_fee_option"
            name="exception-delivery-fee"
            id="exception-delivery-fee"
          />
          <label for="exception-delivery-fee"
            >{{ $t("settings.exception_delivery_fee") }}:</label
          >
        </div>

        <v-text-field
          :disabled="exception_delivery_fee_option == 0"
          v-model="exception_min_distance_delivery"
          filled
          suffix="km"
        />
        &
        <v-text-field
          :disabled="exception_delivery_fee_option == 0"
          v-model="exception_max_amount_delivery"
          filled
          suffix="€"
        />
        =
        <v-text-field
          :disabled="exception_delivery_fee_option == 0"
          v-model="exception_delivery_fee"
          filled
          suffix="€"
        />
      </div>

      <div class="free-delivery">
        <div class="label">
          <input
            type="checkbox"
            v-model="free_delivery_option"
            name="free-delivery"
            id="free-delivery"
          />
          <label for="free-delivery">{{ $t("information.min_order") }}:</label>
        </div>

        <v-text-field
          :disabled="free_delivery_option == 0"
          v-model="minimum_order_free_delivery"
          filled
          suffix="€"
        />
      </div>
      <div class="delivery-type">
        <button
          @click="changeDeliveryFeeType(1)"
          :class="[delivery_fee_type == 1 ? 'outline' : 'offline']"
        >
          {{ $t("info.fixed_fee") }}
        </button>
        <button
          @click="changeDeliveryFeeType(0)"
          :class="[delivery_fee_type == 0 ? 'outline' : 'offline']"
        >
          {{ $t("info.based_radius") }}
        </button>

        <button
          @click="changeDeliveryFeeType(2)"
          :class="[delivery_fee_type == 2 ? 'outline' : 'offline']"
        >
          {{ $t("settings.based_ranges") }}
        </button>
      </div>

      <v-text-field
        v-if="delivery_fee_type == 1"
        v-model="delivery_charge"
        filled
        suffix="€"
        :label="$t('order.delivery_fee')"
      ></v-text-field>

      <v-text-field
        v-if="delivery_fee_type == 0"
        v-model="delivery_fee_ratio"
        filled
        suffix="€/km"
        :label="$t('settings.ratio')"
      ></v-text-field>

      <div v-if="delivery_fee_type == 2" class="ranges">
        <div class="radius">
          <v-text-field
            v-model="delivery_fees[0].from_here"
            filled
            suffix="km"
            :label="$t('info.from')"
          ></v-text-field>
          -
          <v-text-field
            v-model="delivery_fees[0].to_there"
            filled
            :label="$t('info.to')"
            suffix="km"
          ></v-text-field>
          <v-text-field
            v-model="delivery_fees[0].delivery_fee"
            filled
            suffix="€"
            :label="$t('info.fee')"
          ></v-text-field>
        </div>

        <div class="radius">
          <v-text-field
            v-model="delivery_fees[1].from_here"
            @change="autoCorrectFromByIndex(1)"
            filled
            suffix="km"
            :label="$t('info.from')"
          ></v-text-field>
          -
          <v-text-field
            v-model="delivery_fees[1].to_there"
            filled
            :label="$t('info.to')"
            suffix="km"
          ></v-text-field>
          <v-text-field
            v-model="delivery_fees[1].delivery_fee"
            filled
            suffix="€"
            :label="$t('info.fee')"
          ></v-text-field>
        </div>

        <div class="radius">
          <v-text-field
            v-model="delivery_fees[2].from_here"
            @change="autoCorrectFromByIndex(2)"
            filled
            suffix="km"
            :label="$t('info.from')"
          ></v-text-field>
          -
          <v-text-field
            v-model="delivery_fees[2].to_there"
            filled
            :label="$t('info.to')"
            suffix="km"
          ></v-text-field>
          <v-text-field
            v-model="delivery_fees[2].delivery_fee"
            filled
            suffix="€"
            :label="$t('info.fee')"
          ></v-text-field>
        </div>
      </div>

      <button @click="done()" class="fill">{{ $t("information.save") }}</button>
    </div>
    <div id="blur_background" class="blur-bg">
      <delivery-hours-modal
        :delivery_times="delivery_times"
        @delivery_times="deliveryTimes"
      ></delivery-hours-modal>
      <calls-modal></calls-modal>
    </div>
  </div>
</template>
<script>
import BackButton from "../../../components/common/BackButton.vue";
import CallsModal from "../../../components/restaurant/CallsModal.vue";
import CallWaiter from "../../../components/restaurant/CallWaiter.vue";
import DeliveryHoursModal from "../../../components/restaurant/DeliveryHoursModal.vue";

export default {
  components: {
    BackButton,
    CallWaiter,
    CallsModal,
    DeliveryHoursModal,
  },
  mounted() {
    this.fetch();
  },
  updated() {
    this.delivery_fees[2].to_there = this.max_distance_order_delivery;
  },
  methods: {
    fetch() {
      this.global_loading.show = true;
      this.$http
        .get(this.$hostname + "/info", this.restaurant_token.config)
        .then((resp) => {
          this.successRequest();
          this.delivery_fee_type = resp.data.info[0].fixed_delivery;

          if (resp.data.info[0].delivery_fees.length > 1) {
            this.delivery_fees = resp.data.info[0].delivery_fees;
          }

          if (this.delivery_fee_type == 1) {
            this.delivery_charge = this.replaceDot(
              resp.data.info[0].delivery_charge
            );
            this.delivery_fee_ratio = 0;
          } else {
            this.delivery_charge = 0;
            this.delivery_fee_ratio = this.replaceDot(
              resp.data.info[0].delivery_fee_ratio
            );
          }

          this.delivery_times = resp.data.info[0].delivery_times.length
            ? resp.data.info[0].delivery_times
            : resp.data.info[0].opening_times;

          this.first_free_delivery_option =
            resp.data.info[0].first_free_delivery_option;
          this.first_min_amount_free_delivery = this.replaceDot(
            resp.data.info[0].first_min_amount_free_delivery
          );
          this.first_max_distance_free_delivery =
            resp.data.info[0].first_max_distance_free_delivery;

          this.exception_delivery_fee_option =
            resp.data.info[0].exception_delivery_fee_option;

          this.exception_delivery_fee =
            resp.data.info[0].exception_delivery_fee;

          this.exception_max_amount_delivery = this.replaceDot(
            resp.data.info[0].exception_max_amount_delivery
          );
          this.exception_min_distance_delivery =
            resp.data.info[0].exception_min_distance_delivery;

          this.free_delivery_option = resp.data.info[0].free_delivery_option;

          this.minimum_order_free_delivery = this.replaceDot(
            resp.data.info[0].minimum_order_free_delivery
          );

          this.max_preparation_time = resp.data.info[0].max_preparation_time;

          this.max_distance_order_delivery =
            resp.data.info[0].max_distance_order_delivery;
          this.min_amount_order_delivery = this.replaceDot(
            resp.data.info[0].min_amount_order_delivery
          );
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    save() {
      let fd = new FormData();

      fd.append("max_preparation_time", this.max_preparation_time);

      fd.append(
        "min_amount_order_delivery",
        this.replaceComma(this.min_amount_order_delivery)
      );

      fd.append("free_delivery_option", this.free_delivery_option ? 1 : 0);

      fd.append(
        "first_free_delivery_option",
        this.first_free_delivery_option ? 1 : 0
      );
      fd.append(
        "first_min_amount_free_delivery",
        this.replaceComma(this.first_min_amount_free_delivery)
      );
      fd.append(
        "first_max_distance_free_delivery",
        this.first_max_distance_free_delivery
      );

      fd.append(
        "exception_delivery_fee_option",
        this.exception_delivery_fee_option ? 1 : 0
      );
      fd.append("exception_delivery_fee", this.exception_delivery_fee);
      fd.append(
        "exception_max_amount_delivery",
        this.replaceComma(this.exception_max_amount_delivery)
      );
      fd.append(
        "exception_min_distance_delivery",
        this.exception_min_distance_delivery
      );

      fd.append(
        "max_distance_order_delivery",
        this.max_distance_order_delivery
      );

      fd.append(
        "minimum_order_free_delivery",
        this.replaceComma(this.minimum_order_free_delivery)
      );

      fd.append("delivery_times", JSON.stringify(this.delivery_times));

      fd.append("delivery_fee_type", this.delivery_fee_type);

      if (this.delivery_fee_type == 1) {
        fd.append("delivery_charge", this.replaceComma(this.delivery_charge));
        fd.append("delivery_fee_ratio", 0);
      } else {
        fd.append("delivery_charge", 0);
        fd.append(
          "delivery_fee_ratio",
          this.replaceComma(this.delivery_fee_ratio)
        );
      }

      if (this.delivery_fee_type == 2) {
        fd.append("delivery_fees", JSON.stringify(this.delivery_fees));
      }

      this.global_loading.show = true;
      this.$http
        .post(
          this.$hostname + "/delivery-info",
          fd,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    done() {
      this.save();
      this.backToHome();
    },
    backToHome() {
      this.$router.push("/");
    },
    deliveryTimes(val) {
      this.delivery_times = val;
      this.save();
    },
    changeDeliveryFeeType(val) {
      if (val == 2) {
        this.delivery_fees = [
          {
            from_here: 0,
            to_there: 0,
            delivery_fee: 0,
          },
          {
            from_here: 0,
            to_there: 0,
            delivery_fee: 0,
          },
          {
            from_here: 0,
            to_there: 0,
            delivery_fee: 0,
          },
        ];
      }
      this.delivery_fee_type = val;
    },
    autoCorrectFromByIndex(index) {
      if (this.delivery_fee_type == 1) {
        this.delivery_fees[index].from_here =
          this.delivery_fees[index - 1].to_there;
      }
    },
  },
  data() {
    return {
      delivery_times: [],
      delivery_charge: 0,
      delivery_fee_ratio: 0,
      minimum_order_free_delivery: 0,
      free_delivery_option: 0,
      min_preparation_time: "",
      max_preparation_time: "",
      max_distance_order_delivery: 0,
      min_amount_order_delivery: 0,
      delivery_fee_type: 0, //fixed=1, per km=0, ranges=2
      first_free_delivery_option: 0,
      first_min_amount_free_delivery: 0,
      first_max_distance_free_delivery: 0,
      exception_delivery_fee_option: 0,
      exception_delivery_fee: 0,
      exception_max_amount_delivery: 0,
      exception_min_distance_delivery: 0,
      delivery_fees: [
        {
          from_here: 0,
          to_there: 0,
          delivery_fee: 0,
        },
        {
          from_here: 0,
          to_there: 0,
          delivery_fee: 0,
        },
        {
          from_here: 0,
          to_there: 0,
          delivery_fee: 0,
        },
      ],
    };
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}

.content {
  height: 100vh;
  overflow-y: scroll;
  padding: 78px 16px 96px;
}
.delivery-type {
  width: 100%;
  display: flex;
  padding: 16px 0;
  justify-content: space-between;
}
.delivery-type button {
  width: 48.5%;
}
.hours {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #f0edf2;
  margin-bottom: 12px;
}
.hours button {
  font-size: 14px;
  width: 100%;
  border-radius: 8px;
  height: 42px;
}

.delivery {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.free-delivery {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.label {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 70%;
  font-weight: 400;
  font-size: 16px;
  color: #a3a3a3;
}

h4 {
  padding: 16px 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
}

button.fill {
  min-height: 48px;
  margin-top: 12px;
}

label.min_order {
  min-width: 80%;
}

.outline {
  font-weight: 400;
  border: 1px solid #5a187a;
  color: #5a187a;
}

.note {
  color: #836d8f;
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 12px;
}

.hour {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.options {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
}

.options input {
  appearance: none;
  outline: none;
}
.option {
  border: 1px solid #8f93bf;
  border-radius: 8px;
  width: 50%;
  height: 40px;
  color: #4c54b3;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
}
.active {
  color: #f5f6f7;
  background-color: #4c54b3;
  cursor: initial;
}
.v-text-field.v-text-field--enclosed {
  max-height: 68px;
}

.ranges {
  display: flex;
  flex-direction: column;
}

.radius {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
}
.outline {
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  color: #6a4080;
}
.offline {
  border: 1px solid #b3b3b3;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  color: #4d4d4d;
}
h3 {
  font-weight: 500;
  font-size: 18px;
  color: #737373;
  text-align: left;
}
</style>
