<template>
    <div class="info">
      <div class="header">
        <div class="container">
          <back-button :back_to="'/lunch'"></back-button>
          <div class="title">{{ $t("home.lunch") }}</div>
          <call-waiter></call-waiter>
        </div>
      </div>
      <div class="content">
        <div class="controls">
          <div class="btn">
            <div class="switch">
              <label for="make_order">{{ "Sama lista joka viikko" }}</label>
  
              <v-app>
                <v-switch
                  @change="setLunchType()"
                  color="#412250"
                  v-model="lunch_type"
                  :flat="true"
                  inset
                ></v-switch>
              </v-app>
              <label for="make_order">{{ "Eri lista joka viikko" }}</label>
            </div>
          </div>
        </div>
  
        <div class="input">
          <label class="active" for="desc">{{ "Viikon viesti" }}</label>
          <textarea
            class="active"
            type="text"
            id="desc"
            v-model="lunch_message"
          ></textarea>
        </div>
  
        <div class="lunch_time">
          <h2>
            {{ "Lunch time:" }}
          </h2>
  
          <div class="hour">
            <v-text-field
              v-model="start_lunch"
              filled
              type="time"
              :label="$t('info.from')"
            ></v-text-field>
            -
            <v-text-field
              v-model="end_lunch"
              filled
              :label="$t('info.to')"
              type="time"
            ></v-text-field>
          </div>
        </div>
  
        <v-text-field v-model="lunch_link" filled label="Lounas link"></v-text-field>
  
  
        <button @click="done()" class="fill">{{ $t("information.save") }}</button>
      </div>
  
      <div id="blur_background" class="blur-bg">
        <calls-modal></calls-modal>
      </div>
    </div>
  </template>
  <script>
  import BackButton from "../../components/common/BackButton.vue";
  import CallsModal from "../../components/restaurant/CallsModal.vue";
  import CallWaiter from "../../components/restaurant/CallWaiter.vue";
  
  export default {
    components: {
      BackButton,
      CallWaiter,
      CallsModal,
    },
    async mounted() {
      this.fetchLunchSettings();
    },
    methods: {
      fetchLunchSettings() {
        this.$http
          .get(this.$hostname + "/lunch", this.restaurant_token.config)
          .then((resp) => {
            this.lunch_type = resp.data.result.lunch_type;
            this.start_lunch = resp.data.result.start_lunch;
            this.end_lunch = resp.data.result.end_lunch;
            this.lunch_message = resp.data.result.lunch_message;
            this.lunch_link = resp.data.result.lunch_link;
          })
          .catch((err) => {
            this.showErrors(err);
          });
      },
      setLunchType() {
        let data = {};
        this.$http
          .patch(
            this.$hostname + "/lunch-type",
            data,
            this.restaurant_token.config
          )
          .then((resp) => {
            this.successRequest();
          })
          .catch((err) => {
            this.showErrors(err);
          });
      },
      save() {
        let data = {
          start_lunch: this.start_lunch,
          end_lunch: this.end_lunch,
          lunch_message: this.lunch_message,
          lunch_link: this.lunch_link,
        };
        this.global_loading.show = true;
        this.$http
          .patch(this.$hostname + "/lunch", data, this.restaurant_token.config)
          .then((resp) => {
            this.successRequest();
          })
          .catch((err) => {
            this.showErrors(err);
          });
      },
      done() {
        this.save();
        this.backToHome();
      },
      backToHome() {
        this.$router.push("/lunch");
      },
    },
  
    data() {
      return {
        start_lunch: "",
        end_lunch: "",
        lunch_message: "",
        lunch_type: 0,
        lunch_link: "",
      };
    },
  };
  </script>
  <style scoped>
  .container {
    justify-content: space-between;
  }
  
  h2 {
    font-weight: 500;
    font-size: 20px;
    color: #737373;
  }
  .content {
    height: 100vh;
    overflow-y: scroll;
    padding: 78px 16px 96px;
  }
  
  .icon {
    width: 24px;
    height: 24px;
    min-width: 24px;
  }
  
  button.fill {
    min-height: 48px;
    margin-top: 12px;
  }
  
  .lunch_time {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0 0;
  }
  
  .hour {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: center;
  }
  .controls {
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding-top: 8px;
    align-items: stretch;
    width: 100%;
    justify-content: center;
  }
  .controls .btn {
    max-height: 50px;
    position: relative;
    padding: 12px;
    justify-content: center;
    background-color: transparent;
    border-bottom: 1px solid #f8f9fa;
  }
  .switch {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 400;
    font-size: 16px;
    color: #412250;
  }
  .hour div {
    width: 32%;
  }
  </style>
  