<template>
  <div id="calls_modal" class="modal">
    <div class="question">
      <div
        v-for="(call, index) in restaurant_calls.data"
        :key="index"
        class="btn call"
      >
        <div class="desc">
          {{
            (call.table_number == 0
              ? $t("order.take_away") +
                " - " +
                $t("order.order_number") +
                " " +
                call.order_id
              : $t("calls.table") + " " + call.table_number) +
            " " +
            (call.type == "help"
              ? $t("calls.need_waiter")
              : $t("calls.pay_request") + " " + formatPrice(call.total))
          }}
        </div>
        <div @click="done(call)" class="got-it">{{ $t("calls.got_it") }}</div>
      </div>
      <empty-calls v-if="restaurant_calls.data.length == 0"></empty-calls>
    </div>
  </div>
</template>
<script>
import EmptyCalls from "./EmptyCalls.vue";
export default {
  components: { EmptyCalls },
  methods: {
    done(call) {
      this.global_loading.show = true;
      this.$http
        .delete(
          this.$hostname + "/got-it-call/" + call.id,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          this.restaurant_calls.data.forEach((element) => {
            if (element.id == call.id) {
              this.restaurant_calls.data.splice(
                this.restaurant_calls.data.indexOf(element),
                1
              );
            }
          });
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
  },
};
</script>
<style scoped>
.modal {
  height: 294px;
  gap: 12px;
  justify-content: flex-start;
  padding: 0;
}
.question {
  background-color: #ffffff;
  height: 100%;
  border-radius: 16px 16px 0 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;
}
.call {
  background-color: #f5f6f7;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: 8px;
}
.desc {
  width: 70%;
  font-size: 16px;
  color: #776282;
}
.got-it {
  width: 128px;
  height: 24px;
  text-align: center;
  padding: 3px;
  background-color: #ffeeeb;
  color: #ff6a4d;
  border-radius: 8px;
  cursor: pointer;
}
</style>