<template>
  <div class="history">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/reservation'"></back-button>

        <div class="title">{{ $t("menu.make_reservation") }}</div>
        <call-waiter></call-waiter>
      </div>
    </div>
    <div class="content">
      <div class="form">
        <div class="input">
          <label class="deactive" for="fullname">{{
            $t("reserve.full_name")
          }}</label>
          <input class="active" type="text" id="fullname" v-model="full_name" />
        </div>

        <div class="input">
          <label class="deactive" for="people">{{
            $t("reserve.people")
          }}</label>
          <input class="active" type="number" id="people" v-model="people" />
        </div>

        <div class="input">
          <label class="deactive" for="email">{{
            $t("registration.email")
          }}</label>
          <input class="active" type="email" id="email" v-model="email" />
        </div>

        <div class="input">
          <label class="deactive" for="phone_number">{{
            $t("information.phone_number")
          }}</label>
          <input
            class="active"
            type="text"
            id="phone_number"
            v-model="phone_number"
          />
        </div>

        <div class="input">
          <label class="deactive" for="date">{{ "Date" }}</label>
          <input class="active" type="date" id="date" v-model="date" />
        </div>

        <div class="input">
          <label class="deactive" for="time">{{ "Time" }}</label>
          <input class="active" type="time" id="time" v-model="time" />
        </div>

        <div class="input">
          <label class="deactive desc" for="message">{{
            $t("reserve.message")
          }}</label>
          <textarea
            class="active"
            type="text"
            id="message"
            v-model="message"
          ></textarea>
        </div>

        <div class="input">
          <label class="deactive" for="table">{{ "Table" }}</label>
          <select
            name="table"
            v-model="selected_table"
            @change="cleanTableCombo()"
            id="table"
          >
            <option v-for="table in table_reservations.tables" :key="table.id" :value="table.id">
              {{ table.name }}
            </option>
          </select>
        </div>

        <div class="input">
          <label class="deactive" for="table_combo">{{
            "Or Table Combination"
          }}</label>
          <select
            name="table_combo"
            v-model="selected_table_combination"
            id="table_combo"
            @change="cleanTable()"
          >
            <option
              v-for="table_combo in table_reservations.table_combinations"
              :key="table_combo.id"
              :value="table_combo.id"
            >
              {{ table_combo.name }}
            </option>
          </select>
        </div>
      </div>
      <button @click="submit()" class="fill">
        {{ $t("information.save") }}
      </button>
    </div>
    <div id="blur_background" class="blur-bg">
      <calls-modal></calls-modal>
    </div>
  </div>
</template>
<script>
import CallWaiter from "../../components/restaurant/CallWaiter.vue";
import CallsModal from "../../components/restaurant/CallsModal.vue";
import BackButton from "../../components/common/BackButton.vue";

export default {
  components: {
    CallWaiter,
    BackButton,
    CallsModal,
  },
  mounted() {
    this.fetchTables();
    this.fetchTableCombos();
  },
  methods: {
    submit() {
      let data = {
        full_name: this.full_name,
        email: this.email,
        message: this.message,
        phone_number: this.phone_number,
        people: this.people,
        datetime: this.date + " " + this.time,
        table_id: this.selected_table,
        table_combination_id: this.selected_table_combination,
        source_id: 2,
      };
      this.global_loading.show = true;
      this.$http
        .post(
          this.$hostname + "/make-reservation",
          data,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          this.$router.push(`/reservation`);
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    cleanTableCombo() {
      this.selected_table_combination = 0;
    },
    cleanTable() {
      this.selected_table = 0;
    },
  },
  data() {
    return {
      tables: [],
      table_combinations: [],
      selected_table: 0,
      selected_table_combination: 0,
      full_name: "",
      email: "",
      phone_number: "",
      date: "",
      time: "",
      message: "",
      people: 1,
    };
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}
.content {
  height: 100vh;
  overflow-y: scroll;
  gap: 8px;
  justify-content: space-between;
}

select {
  width: 100%;
}
</style>
