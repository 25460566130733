<template>
  <div class="reservation">
    <div class="header">
      <div class="container">
        <button
          @click="openPrintReservationModal()"
          class="header-icon print"
        ></button>
        <div class="title">{{ $t("navbar.reservation") }}</div>
        <button
          @click="openReservationSettings()"
          class="header-icon settings"
        ></button>
      </div>
    </div>
    <div class="content" v-if="error_message">{{ error_message }}</div>
    <div class="content" v-else>
      <div v-if="show_settings" class="controls">
        <div class="row">
          <label for="disabling" class="title">{{
            "Sulje varaukset loppupäiväksi:"
          }}</label>
          <div class="switch">
            <v-app>
              <v-switch
                id="disabling"
                @change="switchDisableToday"
                color="#ed3838"
                v-model="disable_today"
                :flat="false"
                inset
              ></v-switch>
            </v-app>
          </div>
        </div>
        <div class="row">
          <div class="toggles">
            <button
              @click="toggle(0)"
              :class="{
                active: currentView === 0,
                inactive: currentView !== 0,
              }"
            >
              Lista
            </button>
            <button
              @click="toggle(1)"
              :class="{
                active: currentView === 1,
                inactive: currentView !== 1,
              }"
            >
              Pöytänäkymä
            </button>
            <button
              @click="toggle(2)"
              :class="{
                active: currentView === 2,
                inactive: currentView !== 2,
              }"
            >
              Aikajana
            </button>
          </div>
          <!-- <label for="view" class="title">{{ "Pöytänäkymä:" }}</label>
          <div class="switch">
            <v-app>
              <v-switch
                id="view"
                color="#ed3838"
                @change="setTableView"
                v-model="table_view"
                :flat="false"
                inset
              ></v-switch>
            </v-app>
          </div> -->
        </div>
      </div>
      <div class="controls"></div>

      <div class="options">
        <router-link class="btn history" to="/reservation-history">
        </router-link>
        <div class="options-btn">
          <button class="btn outline" @click="openWalkinReservationModal()">
            {{ $t("reserve.walkin") }}
          </button>
          <button class="btn outline" @click="openCallReservationModal()">
            {{ $t("reserve.call") }}
          </button>
        </div>
      </div>
      <div class="filters">
        <v-text-field
          v-model="start_date"
          @input="fetchReservations(start_date, end_date)"
          :style="[start_date ? { opacity: '1' } : { opacity: '0.5' }]"
          type="date"
          filled
          :label="$t('history.start_date')"
          pattern="YYYY-MM-DD"
        ></v-text-field>

        <span>-</span>

        <v-text-field
          v-model="end_date"
          @input="fetchReservations(start_date, end_date)"
          :style="[end_date ? { opacity: '1' } : { opacity: '0.5' }]"
          type="date"
          filled
          :label="$t('history.end_date')"
        ></v-text-field>

        <div class="btn sum-people">
          <div class="icon people-icon"></div>
          <div class="people">
            {{ restaurant_reservations.people }}
          </div>
        </div>
      </div>

      <!-- tables -->
      <div class="tables" v-if="currentView == 1">
        <table-reservation
          v-for="table_reservation in table_reservations.data"
          :key="table_reservation.id"
          :table_reservation="table_reservation"
          :default_reservation_length="
            table_reservations.default_reservation_length
          "
        ></table-reservation>
      </div>

      <!-- timeline -->
      <div class="timeline" v-if="currentView == 2">
        <timeline-reservation
          :reservations="restaurant_reservations.confirm"
          :tables="table_reservations.tables"
        >
        </timeline-reservation>
      </div>

      <div
        v-for="reserve in restaurant_reservations.pending"
        :key="reserve.id"
        class="btn"
      >
        <div class="row">
          <div class="datetime">
            <img :src="reserve.reservation_source" width="18" height="18" />

            {{ formatDatetime(reserve.reserved_at) }}
          </div>
          <div class="people">
            {{
              reserve.people == 1
                ? reserve.people + " " + $t("reserve.person")
                : reserve.people + " " + $t("reserve.people")
            }}
            {{
              "(" +
              (reserve.table
                ? reserve.table
                : reserve.table_combination
                ? reserve.table_combination
                : "") +
              ")"
            }}
          </div>
        </div>

        <div class="row">
          <div class="name">
            {{ reserve.user_id ? reserve.user.name : reserve.full_name }}
          </div>
          <a
            :href="
              'tel:' +
              (reserve.user_id
                ? reserve.user.phone_number
                : reserve.phone_number)
            "
          >
            <div class="phone">
              {{
                reserve.user_id
                  ? reserve.user.phone_number
                  : reserve.phone_number
              }}
            </div>
          </a>
        </div>

        <div class="row">
          <div :class="[reserve.status]">
            {{
              reserve.status == "pending"
                ? $t("reserve.pending")
                : reserve.status == "cancelled"
                ? $t("reserve.cancelled")
                : $t("reserve.confirmed")
            }}
          </div>
          <div class="actions">
            <button
              @click="confirm(reserve)"
              v-if="reserve.status == 'pending'"
              class="confirm"
            >
              {{ $t("reserve.confirm") }}
            </button>
            <button
              @click="rejectConfirmation(reserve)"
              v-if="reserve.status == 'pending'"
              class="reject"
            >
              {{ $t("reserve.reject") }}
            </button>
          </div>
        </div>
        <div class="row note">
          <i>
            {{ reserve.message }}
          </i>
        </div>

        <div class="row">
          <v-textarea
            @change="saveRestaurantNote(reserve)"
            v-model="reserve.restaurant_message"
            filled
            rows="1"
            :label="$t('reserve.restaurant_note') + ':'"
            maxlength="1000"
            background-color="#ffffff"
          ></v-textarea>
        </div>
      </div>
      <div
        v-for="reserve in restaurant_reservations.confirm"
        :key="reserve.id"
        class="btn"
      >
        <div class="row">
          <div class="datetime">
            <img :src="reserve.reservation_source" width="18" height="18" />

            {{ formatDatetime(reserve.reserved_at) }}
          </div>
          <div class="people">
            {{
              reserve.people == 1
                ? reserve.people + " " + $t("reserve.person")
                : reserve.people + " " + $t("reserve.people")
            }}
            {{
              "(" +
              (reserve.table
                ? reserve.table
                : reserve.table_combination
                ? reserve.table_combination
                : "") +
              ")"
            }}
            <button @click="edit(reserve)" class="icon edit"></button>
          </div>
        </div>
        <div class="row"></div>
        <div class="row">
          <div class="name">
            {{ reserve.user_id ? reserve.user.name : reserve.full_name }}
          </div>
          <a
            :href="
              'tel:' +
              (reserve.user_id
                ? reserve.user.phone_number
                : reserve.phone_number)
            "
          >
            <div class="phone">
              {{
                reserve.user_id
                  ? reserve.user.phone_number
                  : reserve.phone_number
              }}
            </div>
          </a>
        </div>

        <div class="row">
          <div :class="[reserve.status]">
            {{
              reserve.status == "pending"
                ? $t("reserve.pending")
                : reserve.status == "cancelled"
                ? $t("reserve.cancelled")
                : $t("reserve.confirmed")
            }}
          </div>
          <div class="actions">
            <button
              @click="confirm(reserve)"
              v-if="reserve.status == 'pending'"
              class="confirm"
            >
              {{ $t("reserve.confirm") }}
            </button>
            <button @click="rejectConfirmation(reserve)" class="reject">
              {{ $t("reserve.reject") }}
            </button>
          </div>
        </div>
        <div class="row note">
          <i>
            {{ reserve.message }}
          </i>
        </div>

        <div class="row">
          <v-textarea
            @change="saveRestaurantNote(reserve)"
            v-model="reserve.restaurant_message"
            filled
            rows="1"
            :label="$t('reserve.restaurant_note') + ':'"
            maxlength="1000"
            background-color="#ffffff"
          ></v-textarea>
        </div>
      </div>
      <empty-reserves
        v-if="
          restaurant_reservations.pending.length == 0 &&
          restaurant_reservations.confirm.length == 0
        "
      ></empty-reserves>
    </div>
    <div id="blur_background" class="blur-bg">
      <calls-modal></calls-modal>
      <reservation-modal
        :default_reservation_length="default_reservation_length"
      ></reservation-modal>
      <print-reservation-modal
        :reservations="restaurant_reservations.confirm"
      ></print-reservation-modal>
      <walkin-reservation-modal
        :default_reservation_length="default_reservation_length"
      ></walkin-reservation-modal>
      <call-reservation-modal
        :default_reservation_length="default_reservation_length"
      ></call-reservation-modal>
    </div>
  </div>
</template>
<script>
import CallsModal from "../components/restaurant/CallsModal.vue";
import CallWaiter from "../components/restaurant/CallWaiter.vue";
import EmptyReserves from "../components/restaurant/EmptyReserves.vue";
import TableReservation from "../components/restaurant/TableReservation.vue";
import TimelineReservation from "../components/restaurant/TimelineReservation.vue";
import ReservationModal from "../components/restaurant/ReservationModal.vue";
import PrintReservationModal from "../components/restaurant/PrintReservationModal.vue";
import WalkinReservationModal from "../components/restaurant/WalkinReservationModal.vue";
import CallReservationModal from "../components/restaurant/CallReservationModal.vue";

export default {
  components: {
    CallWaiter,
    CallsModal,
    EmptyReserves,
    TableReservation,
    TimelineReservation,
    ReservationModal,
    PrintReservationModal,
    WalkinReservationModal,
    CallReservationModal,
  },
  mounted() {
    this.currentView = JSON.parse(localStorage.currentView);
    this.fetchReservations();
    this.isTodayDisabled();
    this.fetchTableReservations();
    this.fetchTables();
    this.fetchTableCombos();
    Pusher.logToConsole = false;

    const vm = this;
    let reservation_channel = this.$pusher.subscribe(
      "reservations-" + this.decodeUserIdByToken()
    );

    reservation_channel.bind("reservations", function (data) {
      if (data.id) {
        vm.fetchReservations();
      }
    });
  },
  methods: {
    openPrintReservationModal() {
      this.$router.replace(`/reservation?modal=print`).catch(() => {});
      this.showModal("print_reservation_modal");
    },
    openReservationSettings() {
      this.show_settings = !this.show_settings;
    },
    openWalkinReservationModal() {
      this.$router.replace(`/reservation?modal=walkin`).catch(() => {});
      // this.fetchFreeTables();
      this.showModal("walkin_reservation_modal");
    },
    openCallReservationModal() {
      this.$router.replace(`/reservation?modal=call`).catch(() => {});
      this.showModal("call_reservation_modal");
    },
    isTodayDisabled() {
      this.$http
        .get(this.$hostname + "/disable-today", this.restaurant_token.config)
        .then((resp) => {
          this.successRequest();
          if (resp.data.result) {
            this.disable_today = true;
            this.today_invalid_time = resp.data.result;
          } else {
            this.disable_today = false;
            this.today_invalid_time = {};
          }
        })
        .catch((err) => {
          this.successRequest();
        });
    },
    toggle(index) {
      this.currentView = index;
      localStorage.currentView = index;
    },
    /* setTableView() {
      localStorage.table_view = this.table_view;
    }, */
    switchDisableToday() {
      if (this.today_invalid_time.id) {
        this.$http
          .delete(
            this.$hostname + "/invalid-times/" + this.today_invalid_time.id,
            this.restaurant_token.config
          )
          .then((resp) => {
            this.today_invalid_time = {};
            this.successRequest();
          })
          .catch((err) => {
            this.showErrors(err);
          });
      } else {
        let data = {};
        this.$http
          .post(
            this.$hostname + "/disable-today",
            data,
            this.restaurant_token.config
          )
          .then((resp) => {
            this.today_invalid_time = resp.data.result;
            this.successRequest();
          })
          .catch((err) => {
            this.showErrors(err);
          });
      }
    },
    edit(reserve) {
      this.reservation_processing.data = reserve;

      this.reservation_processing.table_id = reserve.table;

      this.reservation_processing.current_table_id = reserve.table;
      this.reservation_processing.current_table_combination_id =
        reserve.table_combination_id;

      this.$router.replace(`/reservation?modal=reservation`).catch(() => {});

      this.showModal("reservation_modal");
    },
    confirm(reservation) {
      let data = {};
      this.global_loading.show = true;
      this.$http
        .patch(
          this.$hostname + "/confirm-reservation/" + reservation.id,
          data,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          this.fetchReservations();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    rejectConfirmation(reservation) {
      if (confirm("Oletko varma että haluat poistaa varauksen?")) {
        this.rejectReservation(reservation, "reject");
      }
    },

    saveRestaurantNote(reservation) {
      let data = {
        restaurant_message: reservation.restaurant_message,
      };

      this.global_loading.show = true;
      this.$http
        .patch(
          this.$hostname + "/save-reservation-note/" + reservation.id,
          data,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          // this.reserves = resp.data.reservations;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
  },
  data() {
    return {
      reserves: [],
      error_message: "",
      people: "",
      start_date: "",
      end_date: "",
      disable_today: false,
      today_invalid_time: {},
      displayDay: 0,
      displayHour: 0,
      displayMin: 0,
      displaySec: 0,
      default_reservation_length: 0,
      currentView: 0,
      // table_view: false,
      timeline_view: false,
      show_settings: false,
    };
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}
.header-icon {
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-size: 28px;
  width: 42px;
  height: 42px;
  background-color: #f0edf2;
  border-radius: 8px;
}
.print {
  background-image: url("../assets/common/print.svg");
}
.settings {
  background-image: url("../assets/restaurant/navbar/settings_on.svg");
}
.toggles {
  display: flex;
  justify-content: center;
  width: 100%;
}
button {
  padding: 10px 15px;
  margin: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 6px;
}
.active {
  background-color: #2a88d2;
  color: #fff;
}
.inactive {
  background-color: rgb(201, 201, 201);
  color: white;
}
.outline {
  border-color: #2a88d2;
}
.reservation {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.content {
  height: 100%;
  /* overflow-y: scroll; */
  /* padding-bottom: 75px; */
  gap: 8px;
}
.btn {
  padding: 12px 8px;
  font-size: 14px;
  flex-direction: column;
  gap: 4px;
  color: #4d4d4d;
  background-color: #fbf5f5;
}
.options .btn {
  width: 48%;
  justify-content: center;
  background-color: #2a88d2;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
}
.options-btn {
  width: 80%;
  display: flex;
  justify-content: space-between;
}
.options .btn.history {
  width: 15%;
  padding: 0px;
  background-image: url("../assets/restaurant/navbar/history.svg");
  background-position: center;
  background-color: #fbf5f5;
}
.row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.datetime,
.people {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #776282;
}
.name,
.phone {
  color: #000000;
  font-size: 20px;
}
.options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 6px;
}

.fill {
  width: 49%;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  height: 48px;
  color: #ffffff;
  background-color: #412250;
  border: 1px solid #412250;
}

.pending {
  background-color: #f7f31849;
  color: #bcb601;
  padding: 4px 6px;
  border-radius: 12px;
  text-transform: capitalize;
}
.confirmed {
  background-color: #36f71849;
  color: #01bc0d;
  padding: 4px 6px;
  border-radius: 12px;
  text-transform: capitalize;
}

.cancelled {
  background-color: #f7181849;
  color: #bc0101;
  padding: 4px 6px;
  border-radius: 12px;
  text-transform: capitalize;
}
.actions {
  width: 50%;
  display: flex;
  justify-content: space-between;
}

.confirm {
  padding: 4px 8px;
  width: 49%;
  background-color: #36f71853;
  border-radius: 4px;
}

.reject {
  padding: 4px 8px;
  width: 49%;
  background-color: #f7181849;
  border-radius: 4px;
}

.note {
  font-weight: 500;
  font-size: 16px;
  color: tomato;
  justify-content: center;
}
.filters {
  display: flex;
  gap: 8px;
  align-items: center;
}
.sum-people {
  height: 40px;
  width: 40px;
  font-size: 14px;
  padding: 0;
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
}
.people-icon {
  background-image: url("../assets/restaurant/people.png");
}
.switch {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  font-size: 16px;
  color: #412250;
}
.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
.controls {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}
.controls .row {
  justify-content: center;
  gap: 12px;
}
.tables {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
  justify-content: center;
}
.status {
  padding: 0px 8px;
}
</style>
