<template>
  <div class="profile">
    <v-snackbar
      color="#5a187a"
      max-width="5"
      width="5"
      dark
      v-model="show_message"
    >
      {{ message }}

      <template :dark="true" v-slot:action="{ attrs }">
        <v-btn
          color="#fafafa"
          text
          v-bind="attrs"
          @click="show_message = false"
        >
          {{ $t("main_page.close") }}
        </v-btn>
      </template>
    </v-snackbar>
    <div class="header">
      <div class="container">
        <back-button :back_to="'/'"></back-button>
        <div class="title">{{ $t("home.profile") }}</div>
        <call-waiter></call-waiter>
      </div>
    </div>
    <div class="content">
      <div class="form parent">
        <img class="qr" :src="qr" alt="" />
        <div class="actions">
          <button class="link">
            <div class="icon"></div>
            <a id="menu" :href="menu"> {{ $t("profile.open_link") }} </a>
          </button>
          <button @click="copyLink" class="copy">
            <div class="icon"></div>
            {{ $t("profile.copy_link") }}
          </button>
        </div>
        <div class="form">
          <div class="input">
            <label class="deactive" for="name">{{ $t("profile.owner") }}</label>
            <input
              class="active"
              disabled
              type="text"
              id="name"
              v-model="person.name"
            />
          </div>

          <div class="input">
            <label class="deactive" for="email">{{
              $t("profile.email")
            }}</label>
            <input
              class="active"
              disabled
              type="text"
              id="email"
              v-model="person.email"
            />
          </div>

          <div class="input phone_number">
            <label class="deactive" for="phone_number">{{
              $t("information.phone_number")
            }}</label>
            <input
              class="active"
              disabled
              type="text"
              id="phone_number"
              v-model="person.phone_number"
            />
          </div>

          <div class="input restaurant_name">
            <label class="deactive" for="restaurant_name">{{
              $t("profile.name")
            }}</label>
            <input
              class="active"
              disabled
              type="text"
              id="restaurant_name"
              v-model="person.restaurant_name"
            />
          </div>

          <div class="input">
            <label class="deactive" for="zip_code">{{
              $t("information.zip_code")
            }}</label>
            <input
              class="active"
              disabled
              type="text"
              id="zip_code"
              v-model="person.zip_code"
            />
          </div>

          <div class="input">
            <label class="deactive" for="bic">{{ "BIC" }}</label>
            <input
              class="active"
              disabled
              type="text"
              id="bic"
              v-model="person.bic"
            />
          </div>

          <div class="input">
            <label class="deactive" for="iban">{{ "IBAN" }}</label>
            <input
              class="active"
              disabled
              type="text"
              id="iban"
              v-model="person.iban"
            />
          </div>

          <router-link class="btn info" to="/restaurant-info">
            <div class="left">
              <div class="icon"></div>
              <h4 class="caption">{{ $t("home.restaurant_info") }}</h4>
            </div>
            <div class="icon arrow"></div>
          </router-link>

          <router-link class="btn services" to="/services">
            <div class="left">
              <div class="icon"></div>
              <h4 class="caption">{{ $t("home.services") }}</h4>
            </div>
            <div class="icon arrow"></div>
          </router-link>

          <router-link class="btn social_links" to="/socials-links">
            <div class="left">
              <div class="icon"></div>
              <h4 class="caption">{{ $t("home.social_links") }}</h4>
            </div>
            <div class="icon arrow"></div>
          </router-link>

          <div
            class="btn voucher"
            @click.prevent="showModal('voucher_coming_modal')"
          >
            <div class="left">
              <div class="icon"></div>
              <h4 class="caption">{{ $t("home.voucher") }}</h4>
              <div class="status coming">{{ $t("home.coming_soon") }}</div>
            </div>
            <div class="icon arrow"></div>
          </div>

          <router-link class="btn settings" to="/settings">
            <div class="left">
              <div class="icon"></div>
              <h4 class="caption">{{ $t("home.settings") }}</h4>
            </div>
            <div class="icon arrow"></div>
          </router-link>
        </div>
      </div>

      <button @click="showModal('logout_modal')" class="outline logout">
        {{ $t("settings.logout") }}
      </button>
    </div>

    <div id="blur_background" class="blur-bg">
      <voucher-coming-modal></voucher-coming-modal>
      <logout-modal></logout-modal>
    </div>
  </div>
</template>

<script>
import BackButton from "../components/common/BackButton.vue";
import LogoutModal from "../components/common/LogoutModal.vue";
import CallWaiter from "../components/restaurant/CallWaiter.vue";
import CallsModal from "../components/restaurant/CallsModal.vue";
import VoucherComingModal from "../components/restaurant/VoucherComingModal.vue";

export default {
  components: {
    BackButton,
    LogoutModal,
    CallWaiter,
    CallsModal,
    VoucherComingModal,
  },
  mounted() {
    this.fetch();
  },
  data() {
    return {
      message: "",
      show_message: false,
      menu: "",
      qr: "",
      person: {
        name: "",
        email: "",
        restaurant_name: "",
        zip_code: "",
        phone_number: "",
        bic: "",
        iban: "",
      },
    };
  },
  methods: {
    copyLink(evt) {
      let copiedText = document.getElementById("menu");

      navigator.clipboard.writeText(copiedText.getAttribute("href")).then(
        () => {
          this.show_message = true;
          this.message = "Copied!";
        },
        () => {
          console.error("there's an error");
        }
      );
    },
    fetch() {
      this.global_loading.show = true;
      this.$http
        .get(this.$hostname + "/profile", this.restaurant_token.config)
        .then((resp) => {
          this.successRequest();
          this.person.restaurant_name = resp.data.profile.restaurant[0].title;
          this.person.phone_number =
            resp.data.profile.restaurant[0].phone_number;
          this.person.zip_code = resp.data.profile.restaurant[0].zip_code;
          this.person.iban = resp.data.profile.restaurant[0].iban;
          this.person.bic = resp.data.profile.restaurant[0].bic;
          this.person.name = resp.data.profile.user[0].name;
          this.person.email = resp.data.profile.user[0].email;
          this.menu =
            this.$customer_app +
            "/menu/" +
            resp.data.profile.restaurant[0].menu.qr_url;
          this.qr =
            this.$public_server +
            "/qr/" +
            resp.data.profile.restaurant[0].menu.qr_url +
            ".png";
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}
.content {
  height: 85vh;
  align-items: center;
  overflow-y: scroll;
  padding: 70px 16px;
  gap: 16px;
  justify-content: space-between;
}

.form {
  width: 100%;
}

.parent {
  width: 100%;
  align-items: center;
  gap: 16px;
}
.actions {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.link,
.copy {
  position: relative;
  border: 1px solid #8f93bf3f;
  border-radius: 8px;
  min-height: 40px;
  width: 48%;
  font-size: 14px;
  color: #412250;
}

.link a {
  width: 100%;
}

.link .icon {
  width: 22px;
  height: 22px;
  background-size: 22px;
}

.copy .icon {
  width: 16px;
  height: 16px;
  background-size: 16px;
}

.link .icon {
  left: 22px;
  top: 8px;
  position: absolute;
  background-image: url("../assets/restaurant/profile/link.svg");
}

.copy .icon {
  left: 22px;
  top: 10px;
  position: absolute;
  background-image: url("../assets/restaurant/profile/copy.svg");
}

.voucher .left .icon {
  background-image: url("../assets/restaurant/home/voucher.svg");
}
.settings .left .icon {
  background-image: url("../assets/restaurant/navbar/settings_on.svg");
}
.services .left .icon {
  background-image: url("../assets/restaurant/home/services.svg");
}

.social_links .left .icon {
  background-image: url("../assets/restaurant/home/social_links.svg");
}

.info .left .icon {
  background-image: url("../assets/restaurant/home/info.svg");
}

.voucher,
.settings {
  width: 100%;
  height: 48px;
  gap: 9px;
}
.coming {
  background-color: #ffeeeb;
  font-weight: 500;
  font-size: 14px;
  color: #ff6a4d;
}
.voucher {
  cursor: pointer;
}
.voucher .arrow {
  opacity: 0.5;
}
.restaurant_name label,
.restaurant_name input {
  width: 50%;
}
.phone_number label {
  width: 40%;
}
.phone_number input {
  width: 60%;
}
.qr {
  width: 136px;
  height: 136px;
}

.logout {
  width: 100%;
  min-height: 48px;
  border: 1px solid #5a187a;
  color: #5a187a;
}
</style>
