<template>
  <div class="category">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/menu'"></back-button>
        <div class="inputs" v-if="renaming">
          <input
            v-model="restaurant_category.title"
            class="title"
            type="text"
          />
          <input
            v-model="restaurant_category.title_en"
            class="title"
            type="text"
            :placeholder="$t('information.name') + '(En)'"
          />
          <input
            v-model="restaurant_category.description"
            class="desc"
            type="text"
          />
          <input v-model="restaurant_category.index" class="desc" type="text" />
        </div>
        <div class="inputs" v-else>
          <div class="title">{{ restaurant_category.title }}</div>
          <div class="desc">{{ restaurant_category.description }}</div>
        </div>
        <div class="actions">
          <button @click="activeRename" class="icon edit"></button>
          <button
            @click="showModal('delete_category_modal')"
            class="icon delete"
          ></button>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="items">
        <div
          class="btn"
          v-for="item in restaurant_category.items"
          :key="item.id"
        >
          <div @click="openItem(item)" class="info">
            <span class="title">
              {{ item.title }}
            </span>
            <span class="price">
              {{ formatPrice(item.price) }}
            </span>
          </div>
          <div
            @click="activeItem(item.id, id)"
            :class="[item.status == 1 ? 'open' : 'closed', 'status']"
          >
            {{
              item.status == 1
                ? $t("restaurant_menu.available")
                : $t("restaurant_menu.unavailable")
            }}
          </div>
        </div>
      </div>
      <button @click="openItem({})" class="fill">
        {{ $t("restaurant_menu.add_new_item") }}
      </button>
    </div>
    <div id="blur_background" class="blur-bg">
      <delete-category-modal
        :title="restaurant_category.title"
        :id="id"
      ></delete-category-modal>
    </div>
  </div>
</template>
<script>
import BackButton from "../../components/common/BackButton.vue";
import DeleteCategoryModal from "../../components/restaurant/DeleteCategoryModal.vue";

export default {
  components: {
    BackButton,
    DeleteCategoryModal,
  },
  mounted() {
    this.fetchItems(this.id);
    this.fetchCategories();
    // this.iOSBottomGapFixer(".content");
  },
  methods: {
    openItem(item) {
      this.$router.push(`/item/${this.id}/${item.id}`);
    },
    activeRename() {
      if (this.renaming) {
        let data = {
          title: this.restaurant_category.title,
          title_en: this.restaurant_category.title_en,
          description: this.restaurant_category.description,
          index: this.restaurant_category.index,
        };
        this.global_loading.show = true;
        this.$http
          .patch(
            this.$hostname + "/categories/" + this.id,
            data,
            this.restaurant_token.config
          )
          .then((resp) => {
            this.successRequest();
            this.renaming = false;
          })
          .catch((err) => {
            this.showErrors(err);
          });
      } else {
        this.renaming = true;
      }
    },
  },
  data() {
    return {
      selected_item: {},
      edit: false,
      extras: [],
      checked_extras: [],
      renaming: false,
    };
  },
  props: {
    type: "",
    id: 0,
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}
.content {
  height: 85%;
  /* overflow-y: scroll; */
  padding-top: 70px;
  padding-bottom: 120px;
}
.items {
  display: flex;
  gap: 8px;
  flex-direction: column;
}
.btn {
  padding: 12px;
  background-color: #fafafa;
}
.info {
  width: 100%;
}
.title {
  font-size: 16px;
  color: #4d4d4d;
  text-align: center;
}

.desc {
  font-size: 12px;
  color: #737373;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 50%;
}

input.desc {
  background-color: #f5f6f7;
  border-radius: 8px;
  padding: 4px;
}

input.title {
  background-color: #f5f6f7;
  border-radius: 8px;
  padding: 4px;
}
.price {
  font-size: 14px;
  color: #b3b3b3;
}

.actions {
  display: flex;
  gap: 8px;
}
.edit,
.delete {
  width: 40px;
  height: 40px;
  background-color: #fafafa;
  border-radius: 8px;
}

.edit {
  background-image: url("../../assets/restaurant/home/pen_gray.svg");
}

.delete {
  background-image: url("../../assets/restaurant/home/trash_gray.svg");
}
button.fill {
  position: fixed;
  bottom: 68px;
  width: 92%;
  margin: auto;
  background-color: #5a187a;
  border: 1px solid #5a187a;
}
.closed {
  min-width: fit-content;
}
@media screen and (min-width: 811px) {
  button {
    max-width: 768px;
  }
}
</style>
