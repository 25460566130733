import Vue from "vue";

Vue.prototype.global_success = Vue.observable({ message: "" });

Vue.prototype.global_error = Vue.observable({
  message: "",
  show: false,
});

Vue.prototype.global_loading = Vue.observable({
  show: false,
});
Vue.prototype.restaurant_average = Vue.observable({ data: 0 });

Vue.prototype.restaurant_categories = Vue.observable({ data: [] });

Vue.prototype.restaurant_reservations = Vue.observable({
  pending: [],
  confirm: [],
  people: "",
});
Vue.prototype.restaurant_orders = Vue.observable({ data: [] });
Vue.prototype.restaurant_new_orders = Vue.observable({ data: [] });

Vue.prototype.restaurant_calls = Vue.observable({ data: [] });
Vue.prototype.order_processing = Vue.observable({ data: {} });

Vue.prototype.lunch = Vue.observable({
  processing: {
    title: "",
    description: "",
    price: null,
    lunch_week_id: null,
    day: "",
  },
  data: [],
  weeks: [],
  days: [],
});

Vue.prototype.reservation_processing = Vue.observable({
  data: {},
  table_id: null,
  current_table_combination_id: null,
});
Vue.prototype.table_reservations = Vue.observable({
  data: {},
  default_reservation_length: null,
  tables: [],
  table_combinations: [],
});

Vue.prototype.restaurant_category = Vue.observable({
  title: "",
  title_en: "",
  description: "",
  items: [],
});

// token for restaurant
Vue.prototype.restaurant_token = Vue.observable({
  config: {
    headers: {
      Authorization: "Bearer " + localStorage.restaurant_token,
    },
  },
});

Vue.prototype.user_signup = Vue.observable({
  name: "",
  email: "",
  phone_number: "",
  password: "",
  expired_at: "",
});

Vue.prototype.blur_background = Vue.observable({
  show: false,
});

export default Vue.mixin({
  data() {
    return {};
  },
});
