<template>
  <div class="modal" id="table_modal">
    <div class="question">
      <div class="options">
        <div class="input">
          <label class="deactive" for="table-number">{{
            $t("reserve.table_number")
          }}</label>
          <input
            class="active"
            type="text"
            id="table-number"
            v-model="table.name"
          />
        </div>
        <div class="input">
          <label class="deactive" for="capacity">{{
            $t("reserve.capacity")
          }}</label>
          <input
            class="active"
            type="text"
            id="capacity"
            v-model="table.capacity"
          />
        </div>
        <div class="input">
          <label class="deactive" for="minimum">{{
            $t("reserve.minimum")
          }}</label>
          <input
            class="active"
            type="text"
            id="minimum"
            v-model="table.minimum"
          />
        </div>

        <div class="input">
          <label class="deactive" for="priority">{{ "Priority" }}</label>
          <input
            class="active"
            type="text"
            id="priority"
            v-model="table.priority"
          />
        </div>
      </div>
      <button @click="submit()" class="fill">
        {{ $t("information.save") }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    submit() {
      if (this.table.id) {
        this.$http
          .patch(
            this.$hostname + "/tables/" + this.table.id,
            this.table,
            this.restaurant_token.config
          )
          .then((resp) => {
            this.successRequest();
            this.$emit("closed_model", true);
            this.closeModal("table_modal");
          })
          .catch((err) => {
            this.showErrors(err);
          });
      } else {
        this.$http
          .post(
            this.$hostname + "/tables",
            this.table,
            this.restaurant_token.config
          )
          .then((resp) => {
            this.successRequest();
            this.$emit("closed_edit_modal", true);
            this.closeModal("table_modal");
          })
          .catch((err) => {
            this.showErrors(err);
          });
      }
    },
  },

  data() {
    return {};
  },
  props: {
    table: {},
  },
};
</script>
<style scoped>
.modal {
  height: 360px;
  gap: 12px;
  justify-content: flex-start;
  padding: 0;
}
.question {
  background-color: #ffffff;
  height: 100%;
  border-radius: 16px 16px 0 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;
}
.options {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
}

.fill {
  background-color: #5a187a;
  border: 1px solid #5a187a;
  max-width: 768px;
  min-height: 40px;
  width: 100%;
  border-radius: 8px;
}
</style>
