<template>
  <div class="days">
    <div class="day" v-for="day in week_days" :key="day">
      <h3>
        {{ $t("lunch." + day) }}
      </h3>
      <div class="lunch-area">
        <div class="lunches">
          <div
            class="lunch"
            v-for="(lunch_day, index) in lunch.data[day]"
            :key="index"
            @click="openLunchModal(lunch_day, day)"
          >
            <div class="title">{{ lunch_day.title }}</div>
            <div class="price">{{ formatPrice(lunch_day.price) }}</div>
          </div>
          <button @click="openLunchModal({}, day)" class="lunch-fill">
            {{ $t("lunch.add_lunch") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FixedLunchDays",
  props: ["days"],
  mounted() {},
  data: () => ({
    week_days: [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ],
  }),
  methods: {
    openLunchModal(lunch, day) {
      lunch.day = day;
      this.lunch.processing = lunch;
      this.showModal("fixed_lunch_day_modal");
    },
  },
};
</script>

<style scoped>
.container {
  justify-content: space-between;
}
.content {
  overflow-y: scroll;
  padding: 84px 16px 72px;
}
.days {
  background-color: #f6f6f6;
  border-radius: 10px;
  padding: 12px;
}
.day {
  margin: 12px 0;
}
h3 {
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 4px;
}
.lunch-area {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #e3e3e3;
  padding: 4px 10px;
}
.lunch-fill {
  background: #408CFF;
  border: none;
  border-radius: 10px;
  margin-top: 8px;
  padding: 8px;
  width: 150px;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  align-self: flex-end;
}
.lunch {
  display: flex;
  justify-content: space-between;
  margin: 6px 0;
  background-color: #f6f6f6;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;
}
.price {
  min-width: 60px;
  text-align: right;
  margin-left: 12px;
}
</style>
